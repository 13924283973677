import React from 'react';
import { Link } from "react-router-dom";

// import Component
import LayoutFullscreen from './../LayoutFullscreen';

const TestComplete = () => {
  const LinkBtn = () => {
    return(
      <div className="testComplete-linkBtn">
        <Link className="btn" to="/potential/index">知能測定検査へ</Link>
      </div>
    )
  }

  return (
    <LayoutFullscreen>
      <section className="testComplete">
        <div className="testComplete-inner">
          <div className="testComplete-mainText">PERSONAL TEST COMPLETE</div>
          <div className="testComplete-subText">
            <span className="testComplete-subTextItem">性格検査完了となります。</span>
            <span className="testComplete-subTextItem">引き続き、知能測定検査を行ってください。</span>
          </div>
          <LinkBtn />
        </div>
      </section>
    </LayoutFullscreen>
  );
}

// export default testDescription;
export default TestComplete;
