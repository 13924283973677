import React from 'react';
import { Link } from "react-router-dom";

// import Component
import LayoutFullscreen from './../LayoutFullscreen';

// import Scss
// import './../assets/scss/b-terms.scss';

const SystemTerms = () => {
  return (
    <LayoutFullscreen>
      <section className="terms">
        <div className="terms-container">
          <div className="terms-title pageTitle">
            <p className="pageTitle-mainTxt">システム利用規約</p>
            <p className="pageTitle-subTxt">System Terms</p>
          </div>
          <dl className="terms-detail">
            <dd className="terms-detail-text">本規約は、株式会社シェルト（以下「当社」といいます）が提供する「WEB適正検査」（以下「本適正検査」といいます）をご利用頂く際の取扱いについて定めることを目的とします。</dd>
            <dt className="terms-detail-index">第1条　本規約への同意</dt>
            <dd className="terms-detail-text">
              <ul className="terms-list">
                <li className="terms-list-item">1．被験者は、当社より個別に発行された、本システムを利用するためのID・パスワード（以下「登録情報」といいます）、及び、URL等の情報を厳重に管理する義務を負い、第三者に譲渡または貸与もしくは開示等をしてはならないものとします。</li>
                <li className="terms-list-item">2．当社は、登録情報によって本システムの利用があった場合、当該利用が登録情報に紐づく被験者によるものとみなします。</li>
              </ul>
            </dd>
            <dt className="terms-detail-index">第2条　登録情報の管理責任</dt>
            <dd className="terms-detail-text">
              <ul className="terms-list">
                <li className="terms-list-item">1．被験者は、当社より個別に発行された、本システムを利用するためのID・パスワード（以下「登録情報」といいます）、及び、URL等の情報を厳重に管理する義務を負い、第三者に譲渡または貸与もしくは開示等をしてはならないものとします。</li>
                <li className="terms-list-item">2．当社は、登録情報によって本システムの利用があった場合、当該利用が登録情報に紐づく被験者によるものとみなします。</li>
              </ul>
            </dd>
            <dt className="terms-detail-index">第3条　設備等</dt>
            <dd className="terms-detail-text">被験者は、本システムを利用するために必要な通信機器、ソフトウェア、その他これらに付随して必要となる全ての設備を自己の費用と責任において準備し、本システムが利用可能な状態に置くものとします。また自己の費用と責任でインターネットにより本サービスに接続するものとします。また、その他すべてのサービスに必要となる交通、通信等必要経費についても、被験者の責任と費用負担において準備、利用するものとします。</dd>
            <dt className="terms-detail-index">第4条　被験者の禁止事項</dt>
            <dd className="terms-detail-text">被験者は、以下の行為をしないものとします。</dd>
            <dd className="terms-detail-text">
              <ul className="terms-list">
                <li className="terms-list-item">1．意図的に虚偽の情報を本システムに登録する行為</li>
                <li className="terms-list-item">2．著作権、商標権、プライバシー権、氏名権、肖像権、名誉等の他人の権利を侵害する行為、他の被験者又は第三者または当社の管理する設備あるいはシステムに不正にアクセスする行為</li>
                <li className="terms-list-item">3．コンピューターウィルス等有害なプログラムを使用する行為</li>
                <li className="terms-list-item">4．犯罪的行為に結びつく行為</li>
                <li className="terms-list-item">5．法令、公序良俗に反する行為、またはそのおそれのある行為</li>
                <li className="terms-list-item">6．本システムに関して第三者へ情報提供を行う行為</li>
                <li className="terms-list-item">7．本システムの運営を妨げる行為、または当社の信頼を毀損する行為</li>
              </ul>
            </dd>
            <dt className="terms-detail-index">第5条　受検結果の開示・修正等</dt>
            <dd className="terms-detail-text">当社は、被験者の本適性検査受検における回答内容およびその検査結果について、被験者および第三者には開示しません。また、返却義務を負わないものとします。被験者者は、本適性検査の受検完了後、回答内容の変更・追加・削除を行うことは出来ません。</dd>
            <dt className="terms-detail-index">第6条　個人情報の保護</dt>
            <dd className="terms-detail-text">当社は、以下の定めにもとづき、被験者個人に関する情報であって、当該情報を構成する氏名、メールアドレス、性別、生年月日その他の情報等により被験者を識別できるもの（当該情報のみでは識別できないが、他の情報と容易に照合することができ、それにより当該個人を識別できるものを含み、以下「個人情報」といいます）を取り扱うものとし、被験者はこれに同意します。</dd>
            <dd className="terms-detail-text">
              <ul className="terms-list">
                <li className="terms-list-item">1．個人情報保護<br />当社は、被験者が提供した個人情報を責任をもって管理するものとし、個人情報の漏洩などの防止に対して、合理的な安全対策を講じるものとします。</li>
                <li className="terms-list-item">2．個人情報の取得と利用の目的および活用範囲について<br />当社による本システムの提供および本適性検査を用いた検査と個人認証に限られます。</li>
                <li className="terms-list-item">3．個人情報の提供に関する被験者本人の任意性の確保および当該情報を提供しなかった場合に生じる結果について<br />本適性検査の受検のために必要となる項目を入力しない場合は、受検することはできません。入力が任意である項目については、この項目を入力しなくても、原則として不利益は生じません。また、受検上必要でない項目の入力は原則として求めません。</li>
                <li className="terms-list-item">4．個人情報の第三者への開示<br />当社は登録された個人情報について、被験者の同意を得ずに第三者に開示することは原則いたしません。ただし、以下の場合は、関係法令に反しない範囲において、被験者の同意なく登録内容を開示することがあります。</li>
                <li className="terms-list-item">
                  <ul className="terms-list">
                    <li className="terms-list-item">(1) 被験者が第三者に不利益を及ぼすと判断した場合</li>
                    <li className="terms-list-item">(2) 裁判所、検察庁、警察、弁護士会またはこれらに準じた権限を有する機関から、個人情報についての開示を求められた場合</li>
                    <li className="terms-list-item">(3) その他被験者の受検に必要であると当社が合理的に判断した場合</li>
                  </ul>
                </li>
                <li className="terms-list-item">5．第三者の範囲<br />次の各号に掲げる場合において、当該個人情報の提供を受ける者は、本条の規定の適用については、第三者に該当しないものとします。</li>
                <li className="terms-list-item">
                  <ul className="terms-list">
                    <li className="terms-list-item">(1) 当社が利用目的の達成に必要な範囲内において個人情報の取扱の全部または一部を委託する場合</li>
                    <li className="terms-list-item">(2) 当社の合併その他の事由による事業の承継に伴って個人情報が提供される場合</li>
                  </ul>
                </li>
                <li className="terms-list-item">6．統計データの利用<br />当社は、提供を受けた個人情報および本テストの検査結果をもとに、個人を特定できない形式による統計データを作成し、当該データにつき何らの制限なく利用・提供ができるものとします。</li>
              </ul>
            </dd>
            <dt className="terms-detail-index">第7条　免責</dt>
            <dd className="terms-detail-text">当社は、以下の定めにもとづき、被験者個人に関する情報であって、当該情報を構成する氏名、メールアドレス、性別、生年月日その他の情報等により被験者を識別できるもの（当該情報のみでは識別できないが、他の情報と容易に照合することができ、それにより当該個人を識別できるものを含み、以下「個人情報」といいます）を取り扱うものとし、被験者はこれに同意します。</dd>
            <dd className="terms-detail-text">
              <ul className="terms-list">
                <li className="terms-list-item">1．当社は本システムの利用により、被験者がいかなる損害（精神的苦痛またはその他の金銭的損失を含む一切の不利益）を受けた場合にも、責任を負わないものとし、一切の損害につき賠償する義務はないものとします。</li>
                <li className="terms-list-item">2．当社は本システムの利用による、被験者と本システムにおける情報等提供者もしくは被験者と第三者との間で生じた争いには一切責任を負わないものとします。</li>
                <li className="terms-list-item">3．当社はコンピュータ、ネットワーク機器、回線などの故障、停止、停電、天災、保守作業、その他の理由により、本システムの中断、遅延、または中止などが発生し、その結果、被験者が損害を被った場合においても、一切の責任を負わないものとします。</li>
                <li className="terms-list-item">4．当社の責に帰さざる事由により、被験者から提供され、登録された情報が喪失した場合には賠償の責任を負いません。</li>
              </ul>
            </dd>
          </dl>
          <div className="terms-agree">
            <Link className="btn" to="/passCreate">同意して次に進む</Link>
          </div>
        </div>
      </section>
    </LayoutFullscreen>
  );
}

export default SystemTerms;
