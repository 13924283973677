// react modules
import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

// import function
import User from './functions/User';

function PublicRoute(props){
  console.log("Component Load : PublicRoute");

  const [NoLoggedIn, setNoLoggedIn] = useState(false);

	if( NoLoggedIn ){
    console.log("NoLoggedIn state is true");
		return( <Route exact path={props.path} component={props.component} /> );
	}

  if(User.loginCheck()){
    console.log("Redirect to Private Index");
    return( <Redirect to="/testDescription" /> );
  }else{
    console.log("NoLoggedIn state set true");
    setNoLoggedIn(true);
  }
}

export default PublicRoute;
