const crypto = require('crypto');

const PASSPHRASE = "CLDfVJmtV3cGTS4Z" // パスフレーズ
const ALGORITHM = "aes-256-cbc" // 暗号化方式
const ENCODING = "hex" // 暗号化時のencoding

export const encrypte = (text) => {
  try {
    const str = Number.isNaN(text)? text : text.toString() ;
    const cipher = crypto.createCipher(ALGORITHM,PASSPHRASE)
    let crypted = cipher.update(str,'utf8',ENCODING)
    crypted += cipher.final(ENCODING);
    // console.log("encrypte SUCCESSED")
    return crypted;
  } catch (error) {
    console.log("encrypte FALSE")
    console.log(error)
    return false;
  }
}

export const decrypte = (text) => {
  try {
    const decipher = crypto.createDecipher(ALGORITHM,PASSPHRASE)
    let dec = decipher.update(text,ENCODING,'utf8')
    dec += decipher.final('utf8');
    // console.log("decrypte SUCCESSED")
    return dec;
  } catch (error) {
    console.log("decrypte FALSE")
    console.log(error)
    return false;
  }
}

