import React from 'react';
// import React, { Component } from 'react';
// import axios from 'axios';

class ProccessMask extends React.Component {
  render() {
    // console.log("ProccessMask.js proccessFlag : " + this.props.proccessFlag);
    // console.log("ProccessMask.js proccessType : " + this.props.proccessType);
    let className = "";
    let proccessText = "";
    let completeText = "";

    switch (this.props.proccessType){
      case "loginCheck":
        proccessText = "LOGIN CHECHKING";
        completeText = "LOGIN COMPLETED";
        className += " is-loginCheck";
        break;
      case "createPass":
        proccessText = "PASSCODE CREATING";
        completeText = "PASSCODE SEND";
        className += " is-createPass";
        break;
      case "testSetting":
        proccessText = "TEST SETTING";
        completeText = "";
        className += " is-testSetting";
        break;
      case "resultSending":
        proccessText = "TEST DATA SENDING";
        completeText = "TEST DATA SEND";
        className += " is-resultSending";
        break;
      default:
        className += "";
    }

    switch (this.props.proccessFlag){
      case "hide":
        className += " is-hide";
        break;
      case "active":
        className += " is-active";
        break;
      case "finish":
        className += " is-finish";
        break;
      case "complete":
        className += " is-complete";
        break;
      default:
        className += "";
    }

    return (
      <div className={'l-fullscreen-proccessMask proccessMask' + className}>
        <div className="proccessMask-running">
          <p className="proccessMask-icon"></p>
          <p className="proccessMask-text">{proccessText}</p>
        </div>
        <div className="proccessMask-complete">
          <p className="proccessMask-completeText">{completeText}</p>
        </div>
      </div>
    );
  }
}

export default ProccessMask;
