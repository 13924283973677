import React from 'react';

const TestStartMask = (props) => {
  let addClass;
  const firstText = props.caseName;
  const firstSubText = "制限時間は " + props.limitTimeStr + " です";
  const startText = "START";
  const [actionStatus, setActionStatus] = React.useState();

  // 導入アニメーション開始処理
  // 初回マウント、及び、props.startFlag が更新時される毎に処理される
  React.useEffect(() => {
    if(props.startFlag){
      console.log("---- startMask Animation start");
      setActionStatus("start");
    }
    return () => {};
  },[props.startFlag]);

  // 導入アニメーション終了処理
  // 初回マウント、及び、actionStatus 更新される毎に処理される
  React.useEffect(() => {
    const delayTime = 1800;
    const timer = setTimeout(()=>{
      if(actionStatus === "start"){
        console.log("---- startMask Animation finish");
        setActionStatus("finish");
      }
    }, delayTime)

    return () => {
      console.log("startMask Animation finish : clearTimeout");
      clearTimeout(timer);
    };
  },[actionStatus]);

  // actionStatus の状態でアニメーションクラスを付け替え
  switch (actionStatus) {
    case "start":
      addClass = " is-anime01";
      break;
    case "finish":
      addClass = " is-anime02";
      break;
    default:
      addClass = "";
  }

  return (
    <div className={'l-basic-testStartMask testStartMask' + addClass}>
      <div className="testStartMask-standby">
        <p className="testStartMask-standby-text">{firstText}</p>
        <p className="testStartMask-standby-subText">{firstSubText}</p>
      </div>
      <div className="testStartMask-start">
        <p className="testStartMask-start-text">{startText}</p>
      </div>
    </div>
  );
}

export default TestStartMask;
