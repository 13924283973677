import React from 'react';
import { withRouter } from "react-router-dom";

// import Component
import LayoutBasic from './../LayoutBasic';
import DescriptionAgreeBtn from './elements/DescriptionAgreeBtn';

// import function
import { iqTestFinishedCheck } from './../../functions/TestStatusCheck';

// import image
import testIcon_01_q_01 from './../../assets/images/iqtest/case04/ex/01_q_01.png';
import testIcon_01_a_01 from './../../assets/images/iqtest/case04/ex/01_a_01.png';
import testIcon_01_a_02 from './../../assets/images/iqtest/case04/ex/01_a_02.png';
import testIcon_01_a_03 from './../../assets/images/iqtest/case04/ex/01_a_03.png';
import testIcon_01_a_04 from './../../assets/images/iqtest/case04/ex/01_a_04.png';
import testIcon_01_a_05 from './../../assets/images/iqtest/case04/ex/01_a_05.png';
import testIcon_02_q_01 from './../../assets/images/iqtest/case04/ex/02_q_01.png';
import testIcon_02_a_01 from './../../assets/images/iqtest/case04/ex/02_a_01.png';
import testIcon_02_a_02 from './../../assets/images/iqtest/case04/ex/02_a_02.png';
import testIcon_02_a_03 from './../../assets/images/iqtest/case04/ex/02_a_03.png';
import testIcon_02_a_04 from './../../assets/images/iqtest/case04/ex/02_a_04.png';
import testIcon_02_a_05 from './../../assets/images/iqtest/case04/ex/02_a_05.png';
import testIcon_03_q_01 from './../../assets/images/iqtest/case04/ex/03_q_01.png';
import testIcon_03_a_01 from './../../assets/images/iqtest/case04/ex/03_a_01.png';
import testIcon_03_a_02 from './../../assets/images/iqtest/case04/ex/03_a_02.png';
import testIcon_03_a_03 from './../../assets/images/iqtest/case04/ex/03_a_03.png';
import testIcon_03_a_04 from './../../assets/images/iqtest/case04/ex/03_a_04.png';
import testIcon_03_a_05 from './../../assets/images/iqtest/case04/ex/03_a_05.png';

const IqTestDescription04 = (props) => {
  React.useEffect(() => {
    iqTestFinishedCheck(props);
  },[props]);

  return (
    <LayoutBasic>
      <section className="iqExample">
        <div className="iqExample-container">
          <div className="iqExample-title pageTitle">
            <p className="pageTitle-mainTxt">検査04 -例題-</p>
            <p className="pageTitle-subTxt">Test04 Examples</p>
          </div>
          <div className="iqExample-attention">本画面の例題説明をよく読んで設問へ進んでください。</div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題1の左側の枠の中には、円と四角形とがあり、小さな点が置いてあります。<br />この小さな点はどんな位置にあるかに注意してください。この点は四角形と円の両方の内側にあります。それでは、右側の列の1から5までの5つの図を見てください。<br />これらの円と四角形の両方の中に点を置くことが出来るのはどれかという問題です。</p>
              <div className="iqExample-sample-test iqTest partD">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題1</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_01} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample01-1" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample01-1"><img src={testIcon_01_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample01-2" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample01-2"><img src={testIcon_01_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample01-3" type="radio" name="sample01" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample01-3"><img src={testIcon_01_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample01-4" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample01-4"><img src={testIcon_01_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample01-5" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample01-5"><img src={testIcon_01_a_05} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は3で、すでに解答欄に選択してあります。<br />3の図だけは、円と四角形の両方の内側に点を置くことが出来ますが、3以外はみんな当てはまりません。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題2を見てください。<br />例題2の枠の中には、例題1と同じ形で、三角形と四角形があり、点が、三角形の内側にあり、また四角形の外側にあります。</p>
              <div className="iqExample-sample-test iqTest partD">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題2</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_01} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample02-1" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample02-1"><img src={testIcon_02_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample02-2" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample02-2"><img src={testIcon_02_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample02-3" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample02-3"><img src={testIcon_02_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample02-4" type="radio" name="sample02" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample02-4"><img src={testIcon_02_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample02-5" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample02-5"><img src={testIcon_02_a_05} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、4です。<br />右側の列の中の5つの図の中で、三角形の内側にあって、また四角形の外側に点を置けるものは、4だけです。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題3の答えはどれですか。<br />回答の列にある図の中で、どれが同じ位置関係に点を置けますか？</p>
              <div className="iqExample-sample-test iqTest partD">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題3</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_01} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample03-1" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample03-1"><img src={testIcon_03_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample03-2" type="radio" name="sample03" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample03-2"><img src={testIcon_03_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample03-3" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample03-3"><img src={testIcon_03_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample03-4" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample03-4"><img src={testIcon_03_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample03-5" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample03-5"><img src={testIcon_03_a_05} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、2です。<br />点が、三角形の内側にあり、同時に円の弧の内側にあります。</p>
            </div>
          </div>
          <div className="iqExample-text is-center">それでは「検査を開始する」のボタンを押して、1問目の問題から始めてください。</div>
          <DescriptionAgreeBtn to="/potential/test04">検査を開始する</ DescriptionAgreeBtn>
        </div>
      </section>
    </LayoutBasic>
  );
}

// export default IqTestDescription04;
export default withRouter(IqTestDescription04);
