// react modules
import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';

// import function
import User from './functions/User';

function PrivateRoute(props){
  console.log("Component Load : PrivateRoute");

  const [LoggedIn, setLoggedIn] = useState(false);

  if( LoggedIn ){
    console.log("LoggedIn state is true");
    return( <Route exact path={props.path} component={props.component} /> );
  }

  if(User.loginCheck()){
    setLoggedIn(true);
    console.log("ReLoad Component : PrivateRoute");
  }else{
    console.log("Redirect to Login");
    return( <Redirect to="/login" /> );
  }
}

export default PrivateRoute;
