import axios from "axios";

// import function
import User from './../../../functions/User';

import { apiUrl_submitTestresult } from './../../../utility/urls';

export const answerDataSend = (answerData) => {
  console.log("---- answerDataSend START");
  console.log("answerDataSend : answerData is ");
  console.log(answerData);
  const userId = User.getUserId();
  console.log("answerDataSend : userId is " + userId);

  let result = "";
  const POST_DATA = {
    userid : userId,
    testResult : answerData
  };
  const API_URL = apiUrl_submitTestresult;
  console.log("POST_DATA : ");
  console.log(POST_DATA);

  // AnswerData送信処理
  return new Promise((resolve,reject) => {
    setTimeout(() => {
      axios.post(API_URL,POST_DATA)
      .then((res) => {
        if(res){ result = res.data; }
        console.log(result);
        if(result['result']){
          console.log("---- answerDataSend COMPLETE");
          resolve();
        }else{
          result = res.data['message'] ? result['message'] : "処理時の予期せぬエラー";
          console.log("---- answerDataSend FAILED")
          reject(result);
        }
      }).catch((res) => {
        console.log(res);
        result = "通信時の予期せぬエラー";
        console.log("---- answerDataSend FAILED")
        reject(result);
      });
    }, 1000);
  })
}
