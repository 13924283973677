import React from 'react';

// import Component
import { scrollToTopOnMount } from './common/functions/scrollToTopOnMount';

// import image
import logoW from './../assets/images/logo_w.png';

const LayoutFullscreen = (props) => {
  React.useEffect(() => {
    scrollToTopOnMount();
  },[]);

  return (
    <div className="l-fullscreen">
      <div className="l-fullscreen-bg">
        <div className="l-fullscreen-bg-obj"></div>
        <div className="l-fullscreen-bg-grid"></div>
      </div>
      <header className="l-fullscreen-header header">
        <img className="header-logo" src={logoW} alt="株式会社シェルト" />
      </header>
      <div className="l-fullscreen-container">
        {props.children}
      </div>
      <footer className="l-fullscreen-footer footer">
        <p className="footer-text">Copyright cierto co.,Ltd</p>
      </footer>
    </div>
  );
}

export default LayoutFullscreen;
