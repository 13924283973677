import React from 'react';
import { withRouter } from 'react-router-dom'

const DescriptionAgreeBtn = (props) => {

  const clickHandle = () => {
    return props.history.push(props.to);
  }

  return(
    <div className="description-agree">
      <button className="btn" onClick={clickHandle}>{props.children}</button>
    </div>
  )
}

export default withRouter(DescriptionAgreeBtn);
