import React from 'react';
import { withRouter } from "react-router-dom";

// import Component
import LayoutFullscreen from './../LayoutFullscreen';

// import function
import { iqTestFinishedCheck } from './../../functions/TestStatusCheck';
import { redirectAction } from './../common/functions/redirectAction';

// import testStting
import TestSetDatas from './config/potentialTestSetting';

const IqTestBreakTime = (props) => {
  React.useEffect(() => {
    iqTestFinishedCheck(props);
  },[props]);

  // テストデータ設定読込
  //   - limitTime    : テスト時間設定
  const testDatas = TestSetDatas["breakTime"];

  // 開始表示の状態変更用のステータスを設定
  const [startFlag, setStartFlag] = React.useState();
  // 開始表示の状態変更用のステータスを設定
  const [finishFlag, setFinishFlag] = React.useState();

  // タイマー処理
  const timeCount = async (limitTime) => {
    await console.log("Timer start");
    await new Promise((resolve) => setTimeout(resolve, limitTime));
    // await new Promise((resolve) => setTimeout(resolve, 3000));
    await console.log("TIME UP !!");
    return setFinishFlag(true);
  }

  // 試験開始時の処理
  const testStartAction = async () => {
    await console.log("---- testStart start");
    await setStartFlag(true);
    return timeCount(testDatas["limitTime"]);
  }

  // 試験終了時の処理
  const testFinishAction = async () => {
    await console.log("---- testFinishAction start");
    return redirectAction(props, testDatas["nextPath"]);
  }

  // 試験開始処理
  if(!startFlag){
    testStartAction();
  }

  // 試験終了処理
  if(finishFlag){
    testFinishAction();
  }

  return (
    <LayoutFullscreen>
      <section className="testComplete">
        <div className="testComplete-inner">
          <div className="testComplete-text">
            <p className="testFinish-mainText">Break Time</p>
            <p className="testComplete-subText">
              <span className="testFinish-subTextItem">ここで3分間休憩します。</span>
              <span className="testFinish-subTextItem">3分経過後、自動的に次のテストへ移動します。</span>
            </p>
          </div>
        </div>
      </section>
    </LayoutFullscreen>
  );
}

// export default IqTestBreakTime;
export default withRouter(IqTestBreakTime);
