import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

// import Component
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import SystemTerms from './components/systemTerms/Index';
import PassCreate from './components/PassCreate';
import Login from './components/Login';
import YgDescription from './components/personalTest/TestDescription';
import YgTest from './components/personalTest/Test';
import YgComplete from './components/personalTest/TestComplete';
import IqTestStart from './components/potentialTest/index';
import IqTest01 from './components/potentialTest/Test01';
import IqTest02 from './components/potentialTest/Test02';
import IqTest03 from './components/potentialTest/Test03';
import IqTest04 from './components/potentialTest/Test04';
import IqTest05 from './components/potentialTest/Test05';
import IqTest06 from './components/potentialTest/Test06';
import IqTest07 from './components/potentialTest/Test07';
import IqTest08 from './components/potentialTest/Test08';
import IqTestDescription01 from './components/potentialTest/Description01';
import IqTestDescription02 from './components/potentialTest/Description02';
import IqTestDescription03 from './components/potentialTest/Description03';
import IqTestDescription04 from './components/potentialTest/Description04';
import IqTestDescription05 from './components/potentialTest/Description05';
import IqTestDescription06 from './components/potentialTest/Description06';
import IqTestDescription07 from './components/potentialTest/Description07';
import IqTestDescription08 from './components/potentialTest/Description08';
import IqTestBreakTime from './components/potentialTest/BreakTime';
import AllComplete from './components/allComplete/Index';
import TestImplemented from './components/testImplemented/Index';
import SystemError from './components/error/Index';

// npm run buildの際に console.log() の動作を停止する
process.env.NODE_ENV !== "development" && (console.log = () => {});

function App() {
  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/" component={SystemTerms} />
        <PublicRoute exact path="/index" component={SystemTerms} />
        <PublicRoute exact path="/passCreate" component={PassCreate} />
        <PublicRoute exact path="/login" component={Login} />
        <PrivateRoute exact path="/testDescription" component={YgDescription} />
        <PrivateRoute exact path="/test" component={YgTest} />
        <PrivateRoute exact path="/testComplete" component={YgComplete} />
        <PrivateRoute exact path="/potential/index" component={IqTestStart} />
        <PrivateRoute exact path="/potential/test01" component={IqTest01} />
        <PrivateRoute exact path="/potential/test02" component={IqTest02} />
        <PrivateRoute exact path="/potential/test03" component={IqTest03} />
        <PrivateRoute exact path="/potential/test04" component={IqTest04} />
        <PrivateRoute exact path="/potential/test05" component={IqTest05} />
        <PrivateRoute exact path="/potential/test06" component={IqTest06} />
        <PrivateRoute exact path="/potential/test07" component={IqTest07} />
        <PrivateRoute exact path="/potential/test08" component={IqTest08} />
        <PrivateRoute exact path="/potential/description01" component={IqTestDescription01} />
        <PrivateRoute exact path="/potential/description02" component={IqTestDescription02} />
        <PrivateRoute exact path="/potential/description03" component={IqTestDescription03} />
        <PrivateRoute exact path="/potential/description04" component={IqTestDescription04} />
        <PrivateRoute exact path="/potential/description05" component={IqTestDescription05} />
        <PrivateRoute exact path="/potential/description06" component={IqTestDescription06} />
        <PrivateRoute exact path="/potential/description07" component={IqTestDescription07} />
        <PrivateRoute exact path="/potential/description08" component={IqTestDescription08} />
        <PrivateRoute exact path="/potential/breakTime" component={IqTestBreakTime} />
        <Route exact path="/allComplete" component={AllComplete} />
        <Route exact path="/testImplemented" component={TestImplemented} />
        <Route exact path="/error" component={SystemError} />
      </Switch>
    </Router>
  )
}

export default App;
