import React from 'react';

// import Component
import LayoutFullscreen from './../LayoutFullscreen';

const SystemError = () => {
  return (
    <LayoutFullscreen>
      <section className="testComplete">
        <div className="testComplete-inner">
          <div className="testComplete-text">
            <p className="testComplete-mainText">SYSTEM ERROR</p>
            <div className="testComplete-subText">
              <span className="testComplete-subTextItem">システムエラーが発生しました</span>
              <span className="testComplete-subTextItem">下記よりシステム担当者へご連絡ください。</span>
              <span className="testComplete-subTextItem"><br />システム担当 ： system@cierto.co.jp</span>
            </div>
          </div>
        </div>
      </section>
    </LayoutFullscreen>
  );
}

// export default testDescription;
export default SystemError;
