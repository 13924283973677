import React from 'react';

// import Component
import LayoutFullscreen from './../LayoutFullscreen';

// import function
import User from '../../functions/User';

const TestCompleted = () => {
  User.logout();

  return (
    <LayoutFullscreen>
      <section className="testComplete">
        <div className="testComplete-inner">
          <div className="testComplete-text">
            <p className="testComplete-mainText">TEST ALL COMPLETED</p>
            <div className="testComplete-subText">
              <span className="testComplete-subTextItem">お疲れ様でした。</span>
              <span className="testComplete-subTextItem">以上で全ての検査が終了となります。</span>
              <span className="testComplete-subTextItem">シェルト採用担当者より、改めてご連絡させて頂きます。</span>
            </div>
          </div>
        </div>
      </section>
    </LayoutFullscreen>
  );
}

// export default testDescription;
export default TestCompleted;
