import React from 'react';

const TestPager = (props) => {

  const listItemCreate = (value) => {
    let itemNumbers = [];
    for(let i=0; i<value; i++){
      let n = i + 1;
      itemNumbers.push(n);
    }
    return itemNumbers;
  }

  const numberItems = listItemCreate(props.sheetCount);

  const pagerClassName =
    props.currentSheet === props.sheetCount + 1?
      " is-finish":
      "";

  const pagerPosition =
    props.currentSheet > props.sheetCount?
      0 - ( ( props.sheetCount - 1 ) * 1 ):
      0 - ( ( props.currentSheet - 1 ) * 1 );

  const pagerPositionStyle = pagerPosition + "em";

  return(
    <div className="test-pager">
      <div className={ "pager" + pagerClassName }>
        <div className="pager-current">
          <ul className="pager-move" style={{marginTop: pagerPositionStyle}}>
            {numberItems.map((number) => 
              <li key={"pagerItem" + number} className="pager-moveItem">{number}</li>
            )}
          </ul>
        </div>
        <p className="pager-max">{props.sheetCount}</p>
      </div>
    </div>
  );
}

export default TestPager;
