import React from 'react';

// import Function
import { setZeroSum2 } from './../../common/functions/setZeroSum';

export const QuestionItem = (props) => {
  const questionValue = props.questionIndex;
  const testIconImagePath = props.testIconPath;

  return(
    <li className="iqTest-question-item">
      <p className="iqTest-question-index">{questionValue}</p>
      <p className="iqTest-question-border"><img src={testIconImagePath} alt="" /></p>
    </li>
  )
}

export const AnswerInputRadioItem = (props) => {
  const answerValue = props.anwserIndex;
  const answerIndex = setZeroSum2(answerValue);
  const answerId   = "answer" + answerIndex;
  const selectValue = props.selectIndex;
  const selectIndex = setZeroSum2(selectValue);
  const selectId = answerId + "-" + selectIndex;
  const testIconImagePath = props.testIconPath;
  const answeredValue = props.answerValue;

  return(
    <li className="iqTest-Answer-item">
      <p className="iqTest-Answer-index">{selectValue}</p>
      <input
        type="radio"
        id={selectId}
        className="iqTest-Answer-checkbox"
        name={answerId}
        value={selectValue}
        onChange={props.handleChange}
        checked={answeredValue === selectValue}
      />
      <label className="iqTest-Answer-checkItem" htmlFor={selectId}><img src={testIconImagePath} alt="" /></label>
    </li>
  )
}

export const AnswerInputCheckItem = (props) => {
  const answerValue = props.anwserIndex;
  const answerIndex = setZeroSum2(answerValue);
  const answerId   = "answer" + answerIndex;
  const selectValue = props.selectIndex;
  const selectIndex = setZeroSum2(selectValue);
  const selectId = answerId + "-" + selectIndex;
  const testIconImagePath = props.testIconPath;
  const answeredValue = props.answerValue;

  return(
    <li className="iqTest-Answer-item">
      <p className="iqTest-Answer-index">{selectValue}</p>
      <input
        type="checkbox"
        id={selectId}
        className="iqTest-Answer-checkbox"
        name={answerId}
        value={selectValue}
        onChange={props.handleChange}
        checked={answeredValue.some( target => target === selectValue)}
      />
      <label className="iqTest-Answer-checkItem" htmlFor={selectId}><img src={testIconImagePath} alt="" /></label>
    </li>
  )
}
