import axios from "axios";

import { apiUrl_issuePassword } from './../utility/urls';

export const passCreateSend = (mail) => {
  console.log("---- passCreateSend START");
  console.log("passCreateSend.js : mail is " + mail);

  let result = "";
  // const POST_DATA = { tel : mail };
  const POST_DATA = { email : mail };
  const API_URL = apiUrl_issuePassword;

  // パスワード発行通信処理
  return new Promise((resolve,reject) => {
    setTimeout(() => {
      console.log("API_URL is " + API_URL);
      axios.post(API_URL,POST_DATA)
      .then((res) => {
        console.log(res.data);
        if(res.data['result']){
          console.log("---- passCreateSend COMPLETE");
          resolve();
        }else{
          result = res.data['message'] ? res.data['message'] : "処理時の予期せぬエラー";
          console.log("---- passCreateSend FAILED");
          reject(result);
        }

      }).catch((res) => {
        console.log(res);
        result = "サーバーとの通信に失敗しました。";
        console.log("---- passCreateSend FAILED");
        reject(result);
      });
    }, 1000);
  })
}
