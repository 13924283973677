import React from 'react';
import { Link, withRouter } from "react-router-dom";

// import Component
import LayoutFullscreen from './../LayoutFullscreen';

// import function
import { PersonalTestFinishedCheck } from './../../functions/TestStatusCheck';

const testDescription = (props) => {
  PersonalTestFinishedCheck(props);

  return (
    <LayoutFullscreen>
      <section className="description">
        <div className="description-container">
          <div className="description-title pageTitle">
            <p className="pageTitle-mainTxt">検査説明</p>
            <p className="pageTitle-subTxt">Test Description</p>
          </div>
          <div className="description-detail">
            <p className="description-detail-text">私たちは、自分の性質についてどの程度まで、正しい判断をもっているのでしょうか。本検査ではそのようなことを調べてみたいと思います。</p>
            <p className="description-detail-text">質問項目のうち、いつもの自分に当てはまるものは「はい」を選択、当てはまらないものには「いいえ」を選択してください。</p>
            <p className="description-detail-text">あまり考えすぎると決められなくなりますから、大体の感じですばやく選択していってください。</p>
          </div>
          <div className="description-sample">
            <div className="description-sample-inner">
              <form className="question">
                <p className="question-number">例題</p>
                <p className="question-text">こまかいめんどうな仕事が好きである</p>
                <div className="question-answerList">
                  <div className="question-answerItem">
                    <input className="question-input" id="question001-1" type="radio" name="question001" value="0" />
                    <label className="question-btn" htmlFor="question001-1">はい</label>
                  </div>
                  <div className="question-answerItem">
                    <input className="question-input" id="question001-2" type="radio" name="question001" value="1" />
                    <label className="question-btn" htmlFor="question001-2">？</label>
                  </div>
                  <div className="question-answerItem">
                    <input className="question-input" id="question001-3" type="radio" name="question001" value="2" />
                    <label className="question-btn" htmlFor="question001-3">いいえ</label>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="description-detail">
            <p className="description-detail-text">「はい」「いいえ」ではどうしても決められない、または、どちらでもないと感じた場合は、「？」を選択してください。<br />選択した後、回答をかえたいときは、改めて選択しなおしてください。</p>
          </div>
          <div className="description-agree">
            <Link className="btn" to="/test">検査を開始する</Link>
          </div>
        </div>
      </section>
    </LayoutFullscreen>
  );
}

// export default testDescription;
export default withRouter(testDescription);
