import React from 'react';
import { withRouter } from "react-router-dom";

// import Component
import LayoutBasic from './../LayoutBasic';
import DescriptionAgreeBtn from './elements/DescriptionAgreeBtn';

// import function
import { iqTestFinishedCheck } from './../../functions/TestStatusCheck';

// import image
import testIcon_01_q_01 from './../../assets/images/iqtest/case03/ex/01_q_01.png';
import testIcon_01_a_01 from './../../assets/images/iqtest/case03/ex/01_a_01.png';
import testIcon_01_a_02 from './../../assets/images/iqtest/case03/ex/01_a_02.png';
import testIcon_01_a_03 from './../../assets/images/iqtest/case03/ex/01_a_03.png';
import testIcon_01_a_04 from './../../assets/images/iqtest/case03/ex/01_a_04.png';
import testIcon_01_a_05 from './../../assets/images/iqtest/case03/ex/01_a_05.png';
import testIcon_01_a_06 from './../../assets/images/iqtest/case03/ex/01_a_06.png';
import testIcon_02_q_01 from './../../assets/images/iqtest/case03/ex/02_q_01.png';
import testIcon_02_a_01 from './../../assets/images/iqtest/case03/ex/02_a_01.png';
import testIcon_02_a_02 from './../../assets/images/iqtest/case03/ex/02_a_02.png';
import testIcon_02_a_03 from './../../assets/images/iqtest/case03/ex/02_a_03.png';
import testIcon_02_a_04 from './../../assets/images/iqtest/case03/ex/02_a_04.png';
import testIcon_02_a_05 from './../../assets/images/iqtest/case03/ex/02_a_05.png';
import testIcon_02_a_06 from './../../assets/images/iqtest/case03/ex/02_a_06.png';
import testIcon_03_q_01 from './../../assets/images/iqtest/case03/ex/03_q_01.png';
import testIcon_03_a_01 from './../../assets/images/iqtest/case03/ex/03_a_01.png';
import testIcon_03_a_02 from './../../assets/images/iqtest/case03/ex/03_a_02.png';
import testIcon_03_a_03 from './../../assets/images/iqtest/case03/ex/03_a_03.png';
import testIcon_03_a_04 from './../../assets/images/iqtest/case03/ex/03_a_04.png';
import testIcon_03_a_05 from './../../assets/images/iqtest/case03/ex/03_a_05.png';
import testIcon_03_a_06 from './../../assets/images/iqtest/case03/ex/03_a_06.png';

const IqTestDescription03 = (props) => {
  React.useEffect(() => {
    iqTestFinishedCheck(props);
  },[props]);

  return (
    <LayoutBasic>
      <section className="iqExample">
        <div className="iqExample-container">
          <div className="iqExample-title pageTitle">
            <p className="pageTitle-mainTxt">検査03 -例題-</p>
            <p className="pageTitle-subTxt">Test03 Examples</p>
          </div>
          <div className="iqExample-attention">本画面の例題説明をよく読んで設問へ進んでください。</div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題1は、左側にある外側の大きな枠の中に、小さな4つの枠があって、その中の右下の枠が空白になっています。<br />この空白の所に、右側の列にある6つの図の中からどれだけぴったりとおさまるかという問題です。<br />正しいものは一つだけです。</p>
              <div className="iqExample-sample-test iqTest partC">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題1</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_01} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-1" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-1"><img src={testIcon_01_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-2" type="radio" name="sample01" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-2"><img src={testIcon_01_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-3" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-3"><img src={testIcon_01_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-4" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-4"><img src={testIcon_01_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-5" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-5"><img src={testIcon_01_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-6" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-6"><img src={testIcon_01_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">例題1では、2番目の図が正解で、すでに解答欄に選択してあります。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題2を見て下さい。<br />どれがぴったりとおさまる正しい答えですか？</p>
              <div className="iqExample-sample-test iqTest partC">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題2</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_01} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-1" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-1"><img src={testIcon_02_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-2" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-2"><img src={testIcon_02_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-3" type="radio" name="sample02" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-3"><img src={testIcon_02_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-4" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-4"><img src={testIcon_02_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-5" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-5"><img src={testIcon_02_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-6" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-6"><img src={testIcon_02_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、3です。<br />左側の大きな枠の中で上の段では白い鳥が反対向きになっています。<br />下の段では右向きの黒い鳥3が空白に一番よくおさまります。<br />上下左右の図の関係を考えることが重要です。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題3を見て下さい。<br />左側の大きな枠の中に小さな図が4つあります。<br />正しい答えは、丸が一つですか、それとも二つですか？それは白い丸ですか、黒い丸ですか？</p>
              <div className="iqExample-sample-test iqTest partC">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題3</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_01} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-1" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-1"><img src={testIcon_03_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-2" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-2"><img src={testIcon_03_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-3" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-3"><img src={testIcon_03_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-4" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-4"><img src={testIcon_03_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-5" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-5"><img src={testIcon_03_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-6" type="radio" name="sample03" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-6"><img src={testIcon_03_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、6で、白い丸一つがよくおさまります。</p>
            </div>
          </div>
          <div className="iqExample-text is-center">それでは「検査を開始する」のボタンを押して、1問目の問題から始めてください。</div>
          <DescriptionAgreeBtn to="/potential/test03">検査を開始する</ DescriptionAgreeBtn>
        </div>
      </section>
    </LayoutBasic>
  );
}

// export default IqTestDescription03;
export default withRouter(IqTestDescription03);
