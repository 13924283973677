import React from 'react';
import { withRouter, Link } from 'react-router-dom'

// import components
import LayoutFullscreen from './../components/LayoutFullscreen';
import ProccessMask from './ProccessMask';

// import functions
import User from './../functions/User';
import { redirectAction } from './common/functions/redirectAction';

class login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loginId: '',
      proccessType: 'loginCheck',
      proccessState: 'none',
      errorMassage: ''
    };

    this.inputChange = this.inputChange.bind(this);
    this.inputSubmit = this.inputSubmit.bind(this);
  }

  inputChange(event) {
    this.setState({
      errorMassage: '',
      [event.target.name]: event.target.value
    });
  }

  inputSubmit(event) {
    event.preventDefault();
    this.loginCheck(this.state.loginId).catch(() => { return false; });
  }

  inputValidation(value){
    return new Promise((resolve,reject) => {
      if(value === '' || value === null){
        reject("パスコードが入力されていません");
      }
      if(isNaN(value)){
        reject("半角英数字のみで入力してください");
      }
      resolve();
    })
  }

  async proccessChange(status,inTime,outTime){
    const delayTime = inTime === "" || inTime === null ? 0 : inTime;
    const timeOut = outTime === "" || outTime === null ? 0 : outTime;
    console.log("delayTime: " + delayTime)
    console.log("timeOut: " + timeOut)
    await setTimeout(() => {
      this.setState({
        proccessState: status
      });
      console.log("proccessChange: " + this.state.proccessState)
    },delayTime)
    await new Promise((resolve) => setTimeout(resolve, timeOut));
    return true;
  }

  async loginCheck(passcode){
    console.log("---- loginCheck");
    this.setState({ errorMassage: '' });
    await this.inputValidation(passcode).catch((error) => {
      console.log("inputValidation error : " + error);
      throw this.loginError(error);
    });
    await this.proccessChange("active",0,0);
    await User.login(passcode).catch((error) => {
      console.log("loginCheck error : " + error);
      throw this.loginError(error);
    });
    await this.proccessChange("finish",0,600);
    await this.proccessChange("complete",0,1000);
    return redirectAction(this.props, "/testDescription");
  }

  loginError(error){
    console.log("---- loginError");
    this.proccessChange("none",0);
    this.setState({
      errorMassage: error
    });
    console.log("Login.js : errorMassage is " + this.state.errorMassage);
  }

  render() {
    return (
      <LayoutFullscreen>
        <section className="login">
          <ProccessMask proccessType={this.state.proccessType} proccessFlag={this.state.proccessState} />
          <div className="login-container">
            <div className="login-title pageTitle is-left">
              <p className="pageTitle-mainTxt">試験者ログイン</p>
              <p className="pageTitle-subTxt">Login for Participant</p>
            </div>
            <div className="login-detail">
              <p className="login-detail-text">下記より、お使いのメールアドレスに送信されたワンタイムパスコードを入力の上、ログイン後テストを実施してください。</p>
            </div>
            <form className="login-form loginForm" action="testDescription" onSubmit={this.inputSubmit}>
              <p className="loginForm-title">ワンタイムパスコード入力</p>
              <input
                className="loginForm-input"
                type="tel"
                maxLength="6"
                name="loginId"
                onChange={this.inputChange}
                autoComplete="off"
              />
              <p className="loginForm-error">{this.state.errorMassage}</p>
              <div className="loginForm-submit">
                <input
                  className="btn"
                  type="submit"
                  value="ログイン"
                />
              </div>
            </form>
            <div className="login-tips">
              <div className="login-tips-inner">
                <p className="login-tips-text">【諸注意】<br />・送られてきたワンタイムパスコードでログインができない<br />・ワンタイムパスコードが届いていない<br />・ワンタイムパスコードの有効期限が切れていた<br />などがあった場合は、下記より、再度ログインパスコードの生成を行ってください。</p>
                <div className="login-tips-passCreate">
                  <Link className="btn is-white" to="/passCreate">ワンタイムパスコード生成</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </LayoutFullscreen>
    );
  }
}

// export default login;
export default withRouter(login);
