import React from 'react';
import { withRouter } from 'react-router-dom'
import axios from "axios";

// import Component
import LayoutFullscreen from './../LayoutFullscreen';
import ProccessMask from './../ProccessMask';
import Pager from "./TestPager";

// import utility data
import { url_errorPage } from './../../utility/urls';

// import function
import { answerDataSend } from "./functions/answerDataSend";
import { PersonalTestFinishedCheck } from './../../functions/TestStatusCheck';

// import JsonData
// import QuestionList from "../json/question.json";

class test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      proccessType: 'testSetting',
      proccessState: 'active',
      jsonTestData: '',
      questionList: '',
      questionCount: 0,
      answerSendDatas: '',
      answerDisplayDatas: '',
      sheetCount: 0,
      currentSheet: 1,
      answeredSheets: 0,
      slideAbleSheets: 1
    };

    this.prop = {
      jsonTestDataPath: "asset/jsons/question.json",
      listingsPerSheet: 12
    };

    PersonalTestFinishedCheck(props);

    this.refSwipe = React.createRef();
    this.refTest = React.createRef();

    this.inputChange = this.inputChange.bind(this);
    this.inputSubmit = this.inputSubmit.bind(this);
  }

  componentDidMount(){
    this.testInitialSet();
  }
  
  inputChange = (event) => {
    this.answerDataSet(event.target.name, event.target.value).catch(() => {
      return false;
    });
  }

  inputSubmit = (event) => {
    event.preventDefault();
    this.answerDataSend().catch((error) => {
      console.log(error);
      return this.props.history.push(url_errorPage);
    });
  }

  redirect(path){
    // console.log("page redirect to '/testDescription'");
    this.props.history.push(path);
  }

  proccessChange = async (status,inTime,outTime) => {
    await console.log("---- proccessChange START");
    const delayTime = inTime === "" || inTime === null ? 0 : inTime;
    const timeOut = outTime === "" || outTime === null ? 0 : outTime;
    await console.log("proccessChange: change for " + this.state.proccessState)
    await console.log("proccessChange: delayTime is " + delayTime)
    await console.log("proccessChange: timeOut is " + timeOut)

    // 発生遅延を含む処理
    await setTimeout(() => {
      this.setState({
        proccessState: status
      });
      console.log("---- proccessChange: COMPLETE")
    },delayTime)

    // 終了遅延処理
    await new Promise((resolve) => setTimeout(resolve, timeOut));
    return true;
  }

  testInitialSet = async () => {
    await console.log("---- testInitialSet START");
    await this.getJson();
    await this.createQuestionList(this.state.jsonTestData);
    const questionList = await this.state.questionList;
    await console.log("testInitialSet: questionList is");
    await console.log(questionList);
    const sheetCount = questionList.length;
    await console.log("testInitialSet: sheetCount is " + sheetCount);
    const currentSheet = this.state.currentSheet;
    await console.log("testInitialSet: currentSheet is " + currentSheet);

    this.setState({
      sheetCount: sheetCount
    });

    await this.sheetSet(this.state.sheetCount + 1);

    await this.answerStateInitialSet(this.state.jsonTestData);
    await this.answerStatusCheck(this.state.answerDisplayDatas);
    await this.sheetChange("latest");
    await this.proccessChange("finish",2000,0);
    return console.log("---- testInitialSet COMPLETE");
  }

  getJson = () => {
    return new Promise((resolve, reject) => {
      console.log("---- getJson START");

      return axios.get(this.prop.jsonTestDataPath)
      .then(res => {
        this.setState({
          jsonTestData: res.data
        });
        resolve(
          console.log("---- getJson COMPLETE")
        );
      }).catch(() => {
        console.log("jsonの取得に失敗!!");
        console.log("---- getJson FAILED")
        reject();
      });

    })
  }

  createQuestionList = (jsonData) => {
    console.log("---- createQuestionList START");

    let questionGroup = [];
    let questionSheets = [];

    return new Promise((resolve) => {
      // 使用変数を初期化
      const len = jsonData.length;
      console.log("createQuestionList: question data length is " + len);
      const listingsPerSheet = this.prop.listingsPerSheet;
      console.log("createQuestionList: listingsPerSheet is " + listingsPerSheet);

      jsonData.map((data, index) => {

        // 12回毎の処理
        // 12項目格納したら、シートとして格納し、格納グループは初期化
        if( index !== 0 && ( index % listingsPerSheet ) === 0 ){
          questionSheets.push(questionGroup);
          questionGroup = [];
        }

        // 全項目時の処理
        // 項目をグループに格納
        questionGroup.push(data);

        // 一番最後の項目時の処理
        // シートに格納する
        if(index === (len - 1) ){
          questionSheets.push(questionGroup);
        }
        
        return true;
      });

      this.setState({
        questionCount: len,
        questionList: questionSheets
      });

      resolve(
        console.log("---- createQuestionList COMPLETE")
      );
    })
  }

  answerStateInitialSet = (jsonData) => {
    console.log("---- answerStateInitialSet START");

    return new Promise((resolve) => {
      let answerSendDatas = {};
      let answerDisplayDatas = {};
      jsonData.map((data)=>{
        let name = "question" + data['no'];
        // answerSendDatas[name] = "1";
        // answerDisplayDatas[name] = "1";
        answerSendDatas[name] = null;
        answerDisplayDatas[name] = null;
        return true;
      })

      this.setState({
        answerDisplayDatas : answerDisplayDatas,
        answerSendDatas : answerSendDatas
      });

      resolve(
        console.log("answerDisplayDatas :"),
        console.log(this.state.answerDisplayDatas),
        console.log("answerSendDatas :"),
        console.log(this.state.answerSendDatas),
        console.log("---- answerStateInitialSet COMPLETE")
      );
    })
  }

  answerDataSend = async () => {
    console.log("---- answerDataSend START");
    this.setState({ proccessType: "resultSending" });
    const datas = [];
    datas['testResult'] = Object.values(this.state.answerSendDatas).join();
    await this.proccessChange("active",0,2000);
    await console.log("answerDisplayDatas is : " + this.state.answerDisplayDatas);
    await console.log("answerSendDatas is : " + datas);
    await answerDataSend(datas['testResult']).catch((error) => {      
      this.proccessChange("none",0,0);
      throw(error);
    });
    await console.log("---- answerDataSend COMPLETE");
    await this.proccessChange("finish",0,600);
    await this.proccessChange("complete",0,1000);
    return this.redirect("/testComplete");
  }

  answerDataSet = async (name,value) => {
    await console.log("---- answerDataSet START");
    await this.answerDataChange(name, value);
    await this.answerStatusCheck(this.state.answerDisplayDatas);

    if(this.state.answeredSheets === this.state.slideAbleSheets){
      await this.sheetChange("latest");
    }

    await console.log("---- answerDataSet COMPLETE")

    return true;
  }

  sendError(error){
    console.log("---- sendError");
    this.proccessChange("none",0,0);
    this.setState({
      errorMassage: error
    });
    console.log("passCreate.js : errorMassage is " + this.state.errorMassage);
  }

  answerDataChange(name,value){
    console.log("---- answerDataChange START");
    return new Promise((resolve) => {
      let answerDisplayDatas = this.state.answerDisplayDatas;
      let answerSendDatas = this.state.answerSendDatas;

      console.log("answerDisplayDataChange: " + name + " to " + value)

      answerDisplayDatas[name] = value;
      if(answerSendDatas[name] === null || answerSendDatas[name] === ""){
      answerSendDatas[name] = value;
        console.log("answerSendDataChange: " + name + " to " + value)
      }else{
        console.log("answerSendDataChange: NotChange")
      }

      this.setState({
        answerDisplayDatas: answerDisplayDatas,
        answerSendDatas: answerSendDatas
      });

      console.log("answerDisplayDatas is : " + Object.values(this.state.answerDisplayDatas).join());
      console.log("answerSendDatas is : " + Object.values(this.state.answerSendDatas).join());
      console.log("---- answerDataChange COMPLETE");
      resolve();
    })
  }

  answerDataCheck = (data) => {
    console.log("---- answerDataCheck START");
    return new Promise((resolve,reject) => {
      let loopCount = 0;
      let errorFlag = false;
      let errorText = null;
      let result = [];

      for(const key in data){
        if(data[key] === null){
          errorFlag = true;
          errorText = "answerDataCheck error: " + key + " is null!!";
          break;
        }

        loopCount = loopCount + 1;
        // console.log("Loop loopCount is " + loopCount);
      }

      if(errorFlag){
        console.log("---- answerDataCheck FAILED")
        reject(() => {
          result[0] = errorFlag;
          result[1] = errorText;
        })
      }else{
        console.log("---- answerDataCheck COMPLETE")
        resolve();
      }
    })
  }

  answerStatusCheck = (data) => {
    console.log("---- answerStatusCheck START");
    return new Promise((resolve) => {
      let loopCount = 0;
      let answeredSheets = 0;

      for(const key in data){
        if(data[key] === null){
          break;
        }

        loopCount = loopCount + 1;
        // console.log("Loop loopCount is " + loopCount);

        if( loopCount !== 0 && ( loopCount % 12 ) === 0 ){
          answeredSheets = answeredSheets + 1;
        }
      }

      if( answeredSheets > this.state.answeredSheets){
        this.setState({
          answeredSheets: answeredSheets
        })
        console.log("answeredSheets update : " + answeredSheets);
      }

      console.log("---- answerStatusCheck COMPLETE");
      resolve();
    })
  }

  sheetSet = (value) => {
    return new Promise((resolve) => {
      console.log("---- sheetSet START");
      // 設定対象要素を取得
      const target = this.refSwipe.current;
      // 参照要素数を取得
      const lenItems = value;
      // 移動要素スタイルの値を設定
      const setValue = lenItems * 100;
      // スタイル用の単位を付与
      const setStyleValue = setValue + "%";
      console.log("sheetSet: width is " + setStyleValue);
      // movement value set
      // this.refSwipe.current.style.width = setStyleValue;
      target.style.width = setStyleValue;
      
      resolve(
        console.log("---- sheetSet COMPLETE")
      );
    })
  }

  sheetChange = async (action) => {
    console.log("---- sheetChange START");
    console.log("move action is " + action);

    // エラー処理
    // action に指定の文言が宣言されていない場合、値をそのまま返す
    if(action !== "add" && action !== "reduce" && action !== "latest"){
      console.log("stop: action wording is not expected.");
      return false;
    }

    // 現在表示しているシート番号を state から取得
    const currentSheet = this.state.currentSheet;
    console.log("sheetChange: currentSheet is " + currentSheet);

    // 現在の回答済シート数を state から取得
    const answeredSheets = this.state.answeredSheets;
    console.log("sheetChange: answeredSheets is " + answeredSheets);

    // 現在の移動可能シート数を state から取得
    let slideAbleSheets = this.state.slideAbleSheets;
    console.log("sheetChange: slideAbleSheets is " + slideAbleSheets);

    if(answeredSheets >= slideAbleSheets){
      slideAbleSheets = answeredSheets + 1;
      console.log("sheetChange: slideAbleSheets update " + slideAbleSheets)
    }

    let targetValue;

    // 加減フラグにより処理を分岐
    // 加算の場合
    if(action === "add"){
      if(currentSheet < slideAbleSheets){
        // 対象値として1加算
        targetValue = currentSheet + 1;
      }else{
        // 現在の値をそのまま返す
        console.log("stop: currentSheet is max");
        return false;
      }
    // 減算の場合
    }else if(action === "reduce"){
      if(currentSheet > 0){
        // 対象値として1減算
        targetValue = currentSheet - 1;
      }else{
        // 現在の値をそのまま返す
        console.log("stop: currentSheet is 0");
        return false;
      }
    }else if(action === "latest"){
      targetValue = slideAbleSheets;
    }

    // 対象要素の移動を実行
    await this.sheetSlide(targetValue);

    // 表示シート番号を更新
    this.setState({
      currentSheet: targetValue,
      slideAbleSheets: slideAbleSheets
    });

    console.log("---- sheetChange COMPLETE");

    // after target value return
    return targetValue;
  }

  sheetSlide = (value) => {
    console.log("---- sheetSlide START");
    return new Promise((resolve) => {
      console.log("sheetSlide: to sheet number " + value);

      // 操作対象要素を設定
      // const target = document.getElementById('js-swipe');
      const target = this.refSwipe.current;

      // 移動要素スタイルの値を設定
      // const movementValue = 0 - ( 100 / max * value );
      const movementValue = 0 - ( ( value -1 ) * 100 );
      // スタイル用の単位を付与
      const movementStyleValue = movementValue + "%";
      console.log("sheetSlide: movementStyleValue is " + movementStyleValue);

      // movement value set
      target.style.marginLeft = movementStyleValue;

      resolve(
        console.log("---- sheetSlide: COMPLETE")
      );
    })
  }

  render() {
    // JSON ファイルの読み込み完了していない間はローディングのみを描画
    if(this.state.questionList){
      return (
        <LayoutFullscreen>
          <form style={{width: "100%"}} name="test" onSubmit={this.inputSubmit}>
            <ProccessMask
              proccessType={this.state.proccessType}
              proccessFlag={this.state.proccessState}
            />
            <div className="test" ref={this.refTest}>
              <Pager
                currentSheet={this.state.currentSheet}
                sheetCount={this.state.sheetCount}
              />
              <div className="test-swipe">
                <ul className="test-swipe-container" ref={this.refSwipe}>
                  {this.state.questionList.map((array,index) =>
                    <QuestionSheet
                      key={"questionSheet" + index}
                      questionData={array}
                      anwerData={this.state.answerDisplayDatas}
                      clickHandler={this.inputChange}
                    />
                  )}
                  <li className="test-swipe-item">
                    <div className="test-sheet testFinish">
                      <div className="testFinish-inner">
                        <div className="testFinish-mainText">ANSWER is COMPLETE</div>
                        <div className="testFinish-subText">
                          <span className="testFinish-subTextItem">全問回答完了となります。</span>
                          <span className="testFinish-subTextItem">下記のボタンから回答データを送信してください。</span>
                          <span className="testFinish-subTextItem">※回答データが送信されないと回答が無効となってしまいますので、ご注意ください</span>
                        </div>
                        <div className="testFinish-submitBtn">
                          <input
                            id="submitBtn"
                            className="question-input"
                            type="submit"
                            value="回答データ送信"
                            onSubmit={this.inputSubmit}
                          />
                          <label className="btn" htmlFor="submitBtn">回答データ送信</label>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <SheetChangeBtn
                sheetChange={this.sheetChange}
                sheetCount={this.state.sheetCount}
                currentSheet={this.state.currentSheet}
                slideAbleSheets={this.state.slideAbleSheets}
              />
            </div>
          </form>
        </LayoutFullscreen>
      );
    }

    return(
      <ProccessMask
        proccessType={this.state.proccessType}
        proccessFlag={this.state.proccessState}
      />
    );
  }
}

function QuestionSheet(props) {
  let insertData = [];
  let dataLeft = [];
  let dataRight = [];

  props.questionData.map((data,index) => {
    insertData = {
      'key': "questionItem" + data['no'],
      'qName': "question" + data['no'],
      'qNumber': "No." + data['no'],
      'qText': data['q'],
      'answer': props.anwerData["question" + data['no']],
      'clickHandler': props.clickHandler
    }

    // if(data['no'] === "001"){
    //   console.log("q001 is " + insertData['answer']);
    // }

    if(index < 6){
      return dataLeft.push(insertData);
    }else{
      return dataRight.push(insertData);
    }
  });

  return(
    <li className="test-swipe-item">
      <div className="test-sheet">
        <ul className="test-sheet-left test-list">
          {dataLeft.map((data,index) =>
            <QuestionItem
              key={data['key']}
              qName={data['qName']}
              qNumber={data['qNumber']}
              qText={data['qText']}
              answer={data['answer']}
              clickHandler={data['clickHandler']}
            />
          )}
        </ul>
        <ul className="test-sheet-right test-list">
          {dataRight.map((data,index) =>
            <QuestionItem
              key={data['key']}
              qName={data['qName']}
              qNumber={data['qNumber']}
              qText={data['qText']}
              answer={data['answer']}
              clickHandler={data['clickHandler']}
            />
          )}
        </ul>
      </div>
    </li>
  )
}

function QuestionItem(props){
  return(
    <li className="test-item question">
      <p className="question-number">{props.qNumber}</p>
      <p className="question-text">{props.qText}</p>
      <div className="question-answerList">
        <div className="question-answerItem">
          <input
            id={props.qName + "-1"}
            name={props.qName}
            className="question-input"
            type="radio"
            value="0"
            onChange={props.clickHandler}
            checked={props.answer === "0"}
          />
          <label className="question-btn" htmlFor={props.qName + "-1"}>はい</label>
        </div>
        <div className="question-answerItem">
          <input
            id={props.qName + "-2"}
            name={props.qName}
            className="question-input"
            type="radio"
            value="1"
            onChange={props.clickHandler}
            checked={props.answer === "1"}
          />
          <label className="question-btn" htmlFor={props.qName + "-2"}>？</label>
        </div>
        <div className="question-answerItem">
          <input
            id={props.qName + "-3"}
            name={props.qName}
            className="question-input"
            type="radio"
            value="2"
            onChange={props.clickHandler}
            checked={props.answer === "2"}
          />
          <label className="question-btn" htmlFor={props.qName + "-3"}>いいえ</label>
        </div>
      </div>
    </li>
  )
}

function SheetChangeBtn(props){
  const pagerBtnBackClassName = props.currentSheet <= 1?
    " is-hide":
    "";

  const pagerBtnNextClassName = props.currentSheet === props.slideAbleSheets?
    " is-hide":
    "";

  return(
    <div className="test-sheetChange">
      <div className="test-sheetChange-wrap">
        <p
          className={"test-sheetChange-btn is-back" + pagerBtnBackClassName }
          onClick={() => props.sheetChange('reduce')}
        >＜</p>
      </div>
      <div className="test-sheetChange-wrap">
        <p
          className={"test-sheetChange-btn is-next" + pagerBtnNextClassName }
          onClick={() => props.sheetChange('add')}
        >＞</p>
      </div>
    </div>
  )
}

// export default test;
export default withRouter(test);
