import axios from "axios";
import User from './User';

import { apiUrl_checkImplemented } from './../utility/urls';

function testStatusCheck() {
  console.log("---- testStatusCheck START");

  let result = "";
  const POST_DATA = { userid : User.getUserId() };
  const API_URL = apiUrl_checkImplemented;

  return new Promise((resolve, reject) => {
    console.log("---- getJson START");

    return axios.post(API_URL,POST_DATA)
      .then((res) => {
        console.log(res.data);
        if(res.data['result']){
          console.log("---- testStatusCheck OK");
          console.log("res.data['status'] : " + res.data['status']);
          resolve(res.data['status']);
        }else{
          console.log("---- testStatusCheck FAILED");
          result = res.data['message'] ? res.data['message'] : "処理時の予期せぬエラー";
          reject(result);
        }
      }).catch((res) => {
        console.log("---- testStatusCheck FAILED");
        console.log(res);
        result = "サーバーとの通信に失敗しました。";
        reject(result['message']);
      });
  })
}

export const PersonalTestFinishedCheck = (props) => {
  // 実施済チェック判定
  console.log("---- PersonalTestFinishedCheck START");
  testStatusCheck().then((value) => {
    console.log("checkResult value is : " + value)
    if(value > 0){
      console.log("Personal Test Finished");
      console.log("Redirect to /potential/index");
      return props.history.push("/potential/index");
    }else{
      console.log("Personal Test Not Implemented");
      return true;
    }
  })
}

export const iqTestFinishedCheck = (props) => {
  // 実施済チェック判定
  console.log("---- IqTestFinishedCheck START");
  testStatusCheck().then((value) => {
    if(value === 2){
      console.log("IQ Test Finished");
      console.log("Redirect to /testImplemented");
      return props.history.push("/testImplemented");
    }else{
      console.log("IQ Test Not Implemented");
      return true;
    }
  })
}
