import React from 'react';

// import Component
import { scrollToTopOnMount } from './common/functions/scrollToTopOnMount';

// import image
import logoW from './../assets/images/logo_w.png';

const LayoutBasic = (props) => {
  React.useEffect(() => {
    scrollToTopOnMount();
  },[]);

  return (
    <div className="l-basic">
      <div className="l-basic-bg">
        <div className="l-basic-bg-obj"></div>
        <div className="l-basic-bg-grid"></div>
      </div>
      <header className="l-basic-header header">
        <img className="header-logo" src={logoW} alt="株式会社シェルト" />
      </header>
      <div className="l-basic-container">
        {props.children}
      </div>
      <footer className="l-basic-footer footer">
        <p className="footer-text">Copyright cierto co.,Ltd</p>
      </footer>
    </div>
  );
}

export default LayoutBasic;
