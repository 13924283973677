import React from 'react';

// import Component
import LayoutFullscreen from './../LayoutFullscreen';

// import function
import User from './../../functions/User';

const TestImplemented = () => {
  User.logout();

  return (
    <LayoutFullscreen>
      <section className="testComplete">
        <div className="testComplete-inner">
          <div className="testComplete-mainText">TEST IMPLEMENTED</div>
          <div className="testComplete-subText">
            <span className="testComplete-subTextItem">このアカウントは検査実施済です。</span>
          </div>
        </div>
      </section>
    </LayoutFullscreen>
  );
}

// export default testDescription;
export default TestImplemented;
