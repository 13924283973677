import React from 'react';
import { withRouter } from "react-router-dom";

// import Component
import LayoutBasic from './../LayoutBasic';
import IqTestStartMask from './elements/StartMask';
import IqTestFinishMask from './elements/FinishMask';
import { AnswerInputCheckItem } from './elements/Items';

// import Function
import { answerDataSet } from './functions/answerDataSet';
import { answerDataSend } from './functions/answerDataSend';
import { redirectAction } from './../common/functions/redirectAction';
import { setZeroSum2 } from './../common/functions/setZeroSum';

// import utility data
import { url_errorPage } from './../../utility/urls';

// import testStting
import testSetDatas from './config/potentialTestSetting';
import initialAnswers from './config/initialAnswers';

// import image
import testIcon_01_a_01 from './../../assets/images/iqtest/case06/01_a_01.png';
import testIcon_01_a_02 from './../../assets/images/iqtest/case06/01_a_02.png';
import testIcon_01_a_03 from './../../assets/images/iqtest/case06/01_a_03.png';
import testIcon_01_a_04 from './../../assets/images/iqtest/case06/01_a_04.png';
import testIcon_01_a_05 from './../../assets/images/iqtest/case06/01_a_05.png';
import testIcon_02_a_01 from './../../assets/images/iqtest/case06/02_a_01.png';
import testIcon_02_a_02 from './../../assets/images/iqtest/case06/02_a_02.png';
import testIcon_02_a_03 from './../../assets/images/iqtest/case06/02_a_03.png';
import testIcon_02_a_04 from './../../assets/images/iqtest/case06/02_a_04.png';
import testIcon_02_a_05 from './../../assets/images/iqtest/case06/02_a_05.png';
import testIcon_03_a_01 from './../../assets/images/iqtest/case06/03_a_01.png';
import testIcon_03_a_02 from './../../assets/images/iqtest/case06/03_a_02.png';
import testIcon_03_a_03 from './../../assets/images/iqtest/case06/03_a_03.png';
import testIcon_03_a_04 from './../../assets/images/iqtest/case06/03_a_04.png';
import testIcon_03_a_05 from './../../assets/images/iqtest/case06/03_a_05.png';
import testIcon_04_a_01 from './../../assets/images/iqtest/case06/04_a_01.png';
import testIcon_04_a_02 from './../../assets/images/iqtest/case06/04_a_02.png';
import testIcon_04_a_03 from './../../assets/images/iqtest/case06/04_a_03.png';
import testIcon_04_a_04 from './../../assets/images/iqtest/case06/04_a_04.png';
import testIcon_04_a_05 from './../../assets/images/iqtest/case06/04_a_05.png';
import testIcon_05_a_01 from './../../assets/images/iqtest/case06/05_a_01.png';
import testIcon_05_a_02 from './../../assets/images/iqtest/case06/05_a_02.png';
import testIcon_05_a_03 from './../../assets/images/iqtest/case06/05_a_03.png';
import testIcon_05_a_04 from './../../assets/images/iqtest/case06/05_a_04.png';
import testIcon_05_a_05 from './../../assets/images/iqtest/case06/05_a_05.png';
import testIcon_06_a_01 from './../../assets/images/iqtest/case06/06_a_01.png';
import testIcon_06_a_02 from './../../assets/images/iqtest/case06/06_a_02.png';
import testIcon_06_a_03 from './../../assets/images/iqtest/case06/06_a_03.png';
import testIcon_06_a_04 from './../../assets/images/iqtest/case06/06_a_04.png';
import testIcon_06_a_05 from './../../assets/images/iqtest/case06/06_a_05.png';
import testIcon_07_a_01 from './../../assets/images/iqtest/case06/07_a_01.png';
import testIcon_07_a_02 from './../../assets/images/iqtest/case06/07_a_02.png';
import testIcon_07_a_03 from './../../assets/images/iqtest/case06/07_a_03.png';
import testIcon_07_a_04 from './../../assets/images/iqtest/case06/07_a_04.png';
import testIcon_07_a_05 from './../../assets/images/iqtest/case06/07_a_05.png';
import testIcon_08_a_01 from './../../assets/images/iqtest/case06/08_a_01.png';
import testIcon_08_a_02 from './../../assets/images/iqtest/case06/08_a_02.png';
import testIcon_08_a_03 from './../../assets/images/iqtest/case06/08_a_03.png';
import testIcon_08_a_04 from './../../assets/images/iqtest/case06/08_a_04.png';
import testIcon_08_a_05 from './../../assets/images/iqtest/case06/08_a_05.png';
import testIcon_09_a_01 from './../../assets/images/iqtest/case06/09_a_01.png';
import testIcon_09_a_02 from './../../assets/images/iqtest/case06/09_a_02.png';
import testIcon_09_a_03 from './../../assets/images/iqtest/case06/09_a_03.png';
import testIcon_09_a_04 from './../../assets/images/iqtest/case06/09_a_04.png';
import testIcon_09_a_05 from './../../assets/images/iqtest/case06/09_a_05.png';
import testIcon_10_a_01 from './../../assets/images/iqtest/case06/10_a_01.png';
import testIcon_10_a_02 from './../../assets/images/iqtest/case06/10_a_02.png';
import testIcon_10_a_03 from './../../assets/images/iqtest/case06/10_a_03.png';
import testIcon_10_a_04 from './../../assets/images/iqtest/case06/10_a_04.png';
import testIcon_10_a_05 from './../../assets/images/iqtest/case06/10_a_05.png';
import testIcon_11_a_01 from './../../assets/images/iqtest/case06/11_a_01.png';
import testIcon_11_a_02 from './../../assets/images/iqtest/case06/11_a_02.png';
import testIcon_11_a_03 from './../../assets/images/iqtest/case06/11_a_03.png';
import testIcon_11_a_04 from './../../assets/images/iqtest/case06/11_a_04.png';
import testIcon_11_a_05 from './../../assets/images/iqtest/case06/11_a_05.png';
import testIcon_12_a_01 from './../../assets/images/iqtest/case06/12_a_01.png';
import testIcon_12_a_02 from './../../assets/images/iqtest/case06/12_a_02.png';
import testIcon_12_a_03 from './../../assets/images/iqtest/case06/12_a_03.png';
import testIcon_12_a_04 from './../../assets/images/iqtest/case06/12_a_04.png';
import testIcon_12_a_05 from './../../assets/images/iqtest/case06/12_a_05.png';
import testIcon_13_a_01 from './../../assets/images/iqtest/case06/13_a_01.png';
import testIcon_13_a_02 from './../../assets/images/iqtest/case06/13_a_02.png';
import testIcon_13_a_03 from './../../assets/images/iqtest/case06/13_a_03.png';
import testIcon_13_a_04 from './../../assets/images/iqtest/case06/13_a_04.png';
import testIcon_13_a_05 from './../../assets/images/iqtest/case06/13_a_05.png';
import testIcon_14_a_01 from './../../assets/images/iqtest/case06/14_a_01.png';
import testIcon_14_a_02 from './../../assets/images/iqtest/case06/14_a_02.png';
import testIcon_14_a_03 from './../../assets/images/iqtest/case06/14_a_03.png';
import testIcon_14_a_04 from './../../assets/images/iqtest/case06/14_a_04.png';
import testIcon_14_a_05 from './../../assets/images/iqtest/case06/14_a_05.png';

const testIconPaths = {
  "01_a_01" : testIcon_01_a_01,
  "01_a_02" : testIcon_01_a_02,
  "01_a_03" : testIcon_01_a_03,
  "01_a_04" : testIcon_01_a_04,
  "01_a_05" : testIcon_01_a_05,
  "02_a_01" : testIcon_02_a_01,
  "02_a_02" : testIcon_02_a_02,
  "02_a_03" : testIcon_02_a_03,
  "02_a_04" : testIcon_02_a_04,
  "02_a_05" : testIcon_02_a_05,
  "03_a_01" : testIcon_03_a_01,
  "03_a_02" : testIcon_03_a_02,
  "03_a_03" : testIcon_03_a_03,
  "03_a_04" : testIcon_03_a_04,
  "03_a_05" : testIcon_03_a_05,
  "04_a_01" : testIcon_04_a_01,
  "04_a_02" : testIcon_04_a_02,
  "04_a_03" : testIcon_04_a_03,
  "04_a_04" : testIcon_04_a_04,
  "04_a_05" : testIcon_04_a_05,
  "05_a_01" : testIcon_05_a_01,
  "05_a_02" : testIcon_05_a_02,
  "05_a_03" : testIcon_05_a_03,
  "05_a_04" : testIcon_05_a_04,
  "05_a_05" : testIcon_05_a_05,
  "06_a_01" : testIcon_06_a_01,
  "06_a_02" : testIcon_06_a_02,
  "06_a_03" : testIcon_06_a_03,
  "06_a_04" : testIcon_06_a_04,
  "06_a_05" : testIcon_06_a_05,
  "07_a_01" : testIcon_07_a_01,
  "07_a_02" : testIcon_07_a_02,
  "07_a_03" : testIcon_07_a_03,
  "07_a_04" : testIcon_07_a_04,
  "07_a_05" : testIcon_07_a_05,
  "08_a_01" : testIcon_08_a_01,
  "08_a_02" : testIcon_08_a_02,
  "08_a_03" : testIcon_08_a_03,
  "08_a_04" : testIcon_08_a_04,
  "08_a_05" : testIcon_08_a_05,
  "09_a_01" : testIcon_09_a_01,
  "09_a_02" : testIcon_09_a_02,
  "09_a_03" : testIcon_09_a_03,
  "09_a_04" : testIcon_09_a_04,
  "09_a_05" : testIcon_09_a_05,
  "10_a_01" : testIcon_10_a_01,
  "10_a_02" : testIcon_10_a_02,
  "10_a_03" : testIcon_10_a_03,
  "10_a_04" : testIcon_10_a_04,
  "10_a_05" : testIcon_10_a_05,
  "11_a_01" : testIcon_11_a_01,
  "11_a_02" : testIcon_11_a_02,
  "11_a_03" : testIcon_11_a_03,
  "11_a_04" : testIcon_11_a_04,
  "11_a_05" : testIcon_11_a_05,
  "12_a_01" : testIcon_12_a_01,
  "12_a_02" : testIcon_12_a_02,
  "12_a_03" : testIcon_12_a_03,
  "12_a_04" : testIcon_12_a_04,
  "12_a_05" : testIcon_12_a_05,
  "13_a_01" : testIcon_13_a_01,
  "13_a_02" : testIcon_13_a_02,
  "13_a_03" : testIcon_13_a_03,
  "13_a_04" : testIcon_13_a_04,
  "13_a_05" : testIcon_13_a_05,
  "14_a_01" : testIcon_14_a_01,
  "14_a_02" : testIcon_14_a_02,
  "14_a_03" : testIcon_14_a_03,
  "14_a_04" : testIcon_14_a_04,
  "14_a_05" : testIcon_14_a_05
}

const IqTest02 = (props) => {

  // テストデータ設定読込
  //   - caseName     : 検査タイトル
  //   - caseCategory : 検査カテゴリ
  //   - qCount       : 設問数を設定
  //   - rCount       : 設問毎の参考画像の数を設定
  //   - aCount       : 設問毎の回答選択肢の数を設定
  //   - nextStep     : 次のフェーズを設定
  //   - nextPath     : 次のフェーズを設定
  //   - limitTime    : テスト時間設定
  const testDatas = testSetDatas["test06"];
  
  // 回答データ格納用配列のフックを設定
  const [answerDatas, setAnswerDatas] = React.useState(initialAnswers["test06"]);
  // 試験開始判定の状態変更用のフックを設定
  const [startFlag, setStartFlag] = React.useState(false);
  // 試験終了判定の状態変更用のフックを設定
  const [finishFlag, setFinishFlag] = React.useState(false);
  // 制限時間計測用タイマーの状態更新用のフックを設定
  const [limitTimerFlag, setLimitTimerFlag] = React.useState(false);

  // 回答ボタン選択時の処理
  const handleChange = (e) => {
    let changeArray = answerDatas[e.target.name];
    let setValue = e.target.value;

    if(changeArray.some(target => target === setValue)){
      if(changeArray[0] === setValue){
        changeArray[0] = "0";
      }
      if(changeArray[1] === setValue){
        changeArray[1] = "0";
      }
    }else{
      if(changeArray[0] === "0"){
        changeArray[0] = setValue;
      }else if(changeArray[1] === "0"){
        changeArray[1] = setValue;
      }  
    }

    console.log(changeArray);
    setAnswerDatas({ ...answerDatas, [e.target.name]: changeArray });
  }

  // 問題数分の配列を作成
  const testValues = [];
  for(let i = 0; i < testDatas["qCount"]; i++ ){
    testValues.push(String(i+1));
  }

  // 問題見本の数だけ配列を作成
  const questionValues = [];
  for(let i = 0; i < testDatas["rCount"]; i++ ){
    questionValues.push(String(i+1));
  }

  // 回答選択肢の数だけ配列を作成
  const answerValues = [];
  for(let i = 0; i < testDatas["aCount"]; i++ ){
    answerValues.push(String(i+1));
  }

  // 試験開始前の処理
  // 初回マウント時に処理される
  React.useEffect(() => {
    const delayTime = 250;
    const timer = setTimeout(()=>{
      if(!startFlag){
        console.log("---- 試験開始前の処理")
        setStartFlag(true);
      }
    }, delayTime)

    return () => {
      clearTimeout(timer);
    };
    // return true;
  },[startFlag]);

  // 試験開始時の処理
  // 初回マウント、及び、startFlag が更新される毎に処理される
  React.useEffect(() => {
    const animationTime = 4000;
    const timer = setTimeout(()=>{
      if(startFlag){
        console.log("---- 試験開始時の処理")
        console.log("limit time ms is " + testDatas["limitTime"])
        console.log("Timer start");
        setLimitTimerFlag(true);
      }
    }, animationTime)

    return () => {
      clearTimeout(timer);
    };
  },[startFlag, testDatas]);

  // 試験中の処理
  // 初回マウント、及び、limitTimerFlag が更新される毎に処理される
  React.useEffect(() => {
    const limitTime = testDatas["limitTime"];
    const timer = setTimeout(()=>{
      if(limitTimerFlag){
        console.log("TIME UP !!");
        setFinishFlag(true);
      }
    }, limitTime)

    return () => {
      clearTimeout(timer);
    };
  },[limitTimerFlag, testDatas]);

  // 試験終了時の処理
  // 初回マウント、及び、finishFlag が更新される毎に処理される
  React.useEffect(() => {
    const animationTime = 4000;
    const timer = setTimeout(()=>{
      if(finishFlag){
        console.log(Object.values(answerDatas));
        answerDataSend(answerDataSet(testDatas["caseNum"], Object.values(answerDatas)))
          .then(
            () => { redirectAction(props, testDatas["nextPath"])}
          ).catch(
            () => { redirectAction(props, url_errorPage)}
          )
      }
    }, animationTime)

    return () => {
      clearTimeout(timer);
    };
  },[finishFlag, testDatas, answerDatas, props]);

  // デバッグ用
  console.log(answerDatas);

  return (
    <LayoutBasic>
      <IqTestStartMask
        caseName={testDatas["caseName"]}
        startFlag={startFlag}
        limitTimeStr={testDatas["limitTimeStr"]}
      />
      <IqTestFinishMask
        finishFlag={finishFlag}
        next = {testDatas["nextStep"]}
      />
      <section className="iqPerform">
        <div className="iqPerform-container">
          <div className="iqPerform-title">{testDatas["caseName"]}</div>
          <div className="iqPerform-attention">本検査は正しい答えは必ず2つあり、2つとも選択しなければ得点になりません。</div>
          {testValues.map((tValue) =>
            <div key={"testItem" + tValue}className={"iqPerform-test iqTest " + testDatas["caseCategory"]}>
              <div className="iqTest-Answer">
                <div className="iqTest-Answer-number">回答選択</div>
                <ul className="iqTest-Answer-list">
                  {answerValues.map((aValue) =>
                    <AnswerInputCheckItem
                      key={"AnswerItem" + setZeroSum2(tValue) + "-" + setZeroSum2(aValue)}
                      anwserIndex={ tValue }
                      selectIndex={ aValue }
                      handleChange={ handleChange }
                      answers={ answerDatas }
                      answerValue={answerDatas["answer" + setZeroSum2(tValue)]}
                      testIconPaths={ testIconPaths }
                      testIconPath={ testIconPaths[setZeroSum2(tValue) + "_a_" + setZeroSum2(aValue)] }
                    />
                  )}
                </ul>
              </div>
            </div>
          )}
          <div className="iqPerform-attention">すべての問題に回答した場合は、制限時間経過までお待ちください。</div>
        </div>
      </section>
    </LayoutBasic>
  );
}

// export default IqTest02;
export default withRouter(IqTest02);
