import React from 'react';
import { withRouter } from "react-router-dom";

// import Component
import LayoutBasic from './../LayoutBasic';
import DescriptionAgreeBtn from './elements/DescriptionAgreeBtn';

// import function
import { iqTestFinishedCheck } from './../../functions/TestStatusCheck';

// import image
import testIcon_blank from './../../assets/images/iqtest/blank.jpg';
import testIcon_01_q_01 from './../../assets/images/iqtest/case05/ex/01_q_01.png';
import testIcon_01_q_02 from './../../assets/images/iqtest/case05/ex/01_q_02.png';
import testIcon_01_q_03 from './../../assets/images/iqtest/case05/ex/01_q_03.png';
import testIcon_01_a_01 from './../../assets/images/iqtest/case05/ex/01_a_01.png';
import testIcon_01_a_02 from './../../assets/images/iqtest/case05/ex/01_a_02.png';
import testIcon_01_a_03 from './../../assets/images/iqtest/case05/ex/01_a_03.png';
import testIcon_01_a_04 from './../../assets/images/iqtest/case05/ex/01_a_04.png';
import testIcon_01_a_05 from './../../assets/images/iqtest/case05/ex/01_a_05.png';
import testIcon_01_a_06 from './../../assets/images/iqtest/case05/ex/01_a_06.png';
import testIcon_02_q_01 from './../../assets/images/iqtest/case05/ex/02_q_01.png';
import testIcon_02_q_02 from './../../assets/images/iqtest/case05/ex/02_q_02.png';
import testIcon_02_q_03 from './../../assets/images/iqtest/case05/ex/02_q_03.png';
import testIcon_02_a_01 from './../../assets/images/iqtest/case05/ex/02_a_01.png';
import testIcon_02_a_02 from './../../assets/images/iqtest/case05/ex/02_a_02.png';
import testIcon_02_a_03 from './../../assets/images/iqtest/case05/ex/02_a_03.png';
import testIcon_02_a_04 from './../../assets/images/iqtest/case05/ex/02_a_04.png';
import testIcon_02_a_05 from './../../assets/images/iqtest/case05/ex/02_a_05.png';
import testIcon_02_a_06 from './../../assets/images/iqtest/case05/ex/02_a_06.png';
import testIcon_03_q_01 from './../../assets/images/iqtest/case05/ex/03_q_01.png';
import testIcon_03_q_02 from './../../assets/images/iqtest/case05/ex/03_q_02.png';
import testIcon_03_q_03 from './../../assets/images/iqtest/case05/ex/03_q_03.png';
import testIcon_03_a_01 from './../../assets/images/iqtest/case05/ex/03_a_01.png';
import testIcon_03_a_02 from './../../assets/images/iqtest/case05/ex/03_a_02.png';
import testIcon_03_a_03 from './../../assets/images/iqtest/case05/ex/03_a_03.png';
import testIcon_03_a_04 from './../../assets/images/iqtest/case05/ex/03_a_04.png';
import testIcon_03_a_05 from './../../assets/images/iqtest/case05/ex/03_a_05.png';
import testIcon_03_a_06 from './../../assets/images/iqtest/case05/ex/03_a_06.png';

const IqTestDescription05 = (props) => {
  React.useEffect(() => {
    iqTestFinishedCheck(props);
  },[props]);

  return (
    <LayoutBasic>
      <section className="iqExample">
        <div className="iqExample-container">
          <div className="iqExample-title pageTitle">
            <p className="pageTitle-mainTxt">検査05 -例題-</p>
            <p className="pageTitle-subTxt">Test05 Examples</p>
          </div>
          <div className="iqExample-attention">本画面の例題説明をよく読んで設問へ進んでください。</div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">検査5の例題1を見てください。<br />左側のはじめから3つの枠を見ると、円がだんだん小さくなっていくのがわかります。<br />4番目の空白の枠の中にはどんな円が入ればよいですか。<br />右側の6つの図の中から最も当てはまるものを一つ選ぶという問題です。</p>
              <div className="iqExample-sample-test iqTest partA">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題1</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_01} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">B</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_02} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">C</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_03} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">D</p>
                      <p className="iqTest-question-border is-blank"><img src={testIcon_blank} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-1" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-1"><img src={testIcon_01_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-2" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-2"><img src={testIcon_01_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-3" type="radio" name="sample01" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-3"><img src={testIcon_01_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-4" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-4"><img src={testIcon_01_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-5" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-5"><img src={testIcon_01_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-6" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-6"><img src={testIcon_01_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、3で、すでに解答欄に選択してあります。<br />3の図が一番、よく当てはまります。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題2を見てください。<br />左側にある枠の中で縦の線が1本、2本、3本と増えていきます。<br />4番目の枠にはどれがはいりますか。</p>
              <div className="iqExample-sample-test iqTest partA">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題2</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_01} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">B</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_02} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">C</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_03} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">D</p>
                      <p className="iqTest-question-border is-blank"><img src={testIcon_blank} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-1" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-1"><img src={testIcon_02_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-2" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-2"><img src={testIcon_02_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-3" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-3"><img src={testIcon_02_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-4" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-4"><img src={testIcon_02_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-5" type="radio" name="sample02" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-5"><img src={testIcon_02_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-6" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-6"><img src={testIcon_02_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、右の図の列から選ぶと5になります。<br />4本の短い線が入った図です。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題3を見てください。<br />左側にある枠の中の図は、1つ目から3つ目までしだいに右に傾いています。<br />より右に傾いて空白の枠の中に入るものはどれですか。</p>
              <div className="iqExample-sample-test iqTest partA">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題3</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_01} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">B</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_02} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">C</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_03} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">D</p>
                      <p className="iqTest-question-border is-blank"><img src={testIcon_blank} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-1" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-1"><img src={testIcon_03_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-2" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-2"><img src={testIcon_03_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-3" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-3"><img src={testIcon_03_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-4" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-4"><img src={testIcon_03_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-5" type="radio" name="sample03" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-5"><img src={testIcon_03_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-6" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-6"><img src={testIcon_03_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、右の図の列から1つだけ選んで5です。</p>
            </div>
          </div>
          <div className="iqExample-text is-center">それでは「検査を開始する」のボタンを押して、1問目の問題から始めてください。</div>
          <DescriptionAgreeBtn to="/potential/test05">検査を開始する</ DescriptionAgreeBtn>
        </div>
      </section>
    </LayoutBasic>
  );
}

// export default IqTestDescription05;
export default withRouter(IqTestDescription05);
