import React from 'react';
import { withRouter } from "react-router-dom";

// import Component
import LayoutBasic from './../LayoutBasic';
import DescriptionAgreeBtn from './elements/DescriptionAgreeBtn';

// import function
import { iqTestFinishedCheck } from './../../functions/TestStatusCheck';

// import image
import testIcon_blank from './../../assets/images/iqtest/blank.jpg';
import testIcon_01_q_01 from './../../assets/images/iqtest/case01/ex/01_q_01.png';
import testIcon_01_q_02 from './../../assets/images/iqtest/case01/ex/01_q_02.png';
import testIcon_01_q_03 from './../../assets/images/iqtest/case01/ex/01_q_03.png';
import testIcon_01_a_01 from './../../assets/images/iqtest/case01/ex/01_a_01.png';
import testIcon_01_a_02 from './../../assets/images/iqtest/case01/ex/01_a_02.png';
import testIcon_01_a_03 from './../../assets/images/iqtest/case01/ex/01_a_03.png';
import testIcon_01_a_04 from './../../assets/images/iqtest/case01/ex/01_a_04.png';
import testIcon_01_a_05 from './../../assets/images/iqtest/case01/ex/01_a_05.png';
import testIcon_01_a_06 from './../../assets/images/iqtest/case01/ex/01_a_06.png';
import testIcon_02_q_01 from './../../assets/images/iqtest/case01/ex/02_q_01.png';
import testIcon_02_q_02 from './../../assets/images/iqtest/case01/ex/02_q_02.png';
import testIcon_02_q_03 from './../../assets/images/iqtest/case01/ex/02_q_03.png';
import testIcon_02_a_01 from './../../assets/images/iqtest/case01/ex/02_a_01.png';
import testIcon_02_a_02 from './../../assets/images/iqtest/case01/ex/02_a_02.png';
import testIcon_02_a_03 from './../../assets/images/iqtest/case01/ex/02_a_03.png';
import testIcon_02_a_04 from './../../assets/images/iqtest/case01/ex/02_a_04.png';
import testIcon_02_a_05 from './../../assets/images/iqtest/case01/ex/02_a_05.png';
import testIcon_02_a_06 from './../../assets/images/iqtest/case01/ex/02_a_06.png';
import testIcon_03_q_01 from './../../assets/images/iqtest/case01/ex/03_q_01.png';
import testIcon_03_q_02 from './../../assets/images/iqtest/case01/ex/03_q_02.png';
import testIcon_03_q_03 from './../../assets/images/iqtest/case01/ex/03_q_03.png';
import testIcon_03_a_01 from './../../assets/images/iqtest/case01/ex/03_a_01.png';
import testIcon_03_a_02 from './../../assets/images/iqtest/case01/ex/03_a_02.png';
import testIcon_03_a_03 from './../../assets/images/iqtest/case01/ex/03_a_03.png';
import testIcon_03_a_04 from './../../assets/images/iqtest/case01/ex/03_a_04.png';
import testIcon_03_a_05 from './../../assets/images/iqtest/case01/ex/03_a_05.png';
import testIcon_03_a_06 from './../../assets/images/iqtest/case01/ex/03_a_06.png';

const IqTestDescription01 = (props) => {
  React.useEffect(() => {
    iqTestFinishedCheck(props);
  },[props]);

  return (
    <LayoutBasic>
      <section className="iqExample">
        <div className="iqExample-container">
          <div className="iqExample-title pageTitle">
            <p className="pageTitle-mainTxt">検査01 -例題-</p>
            <p className="pageTitle-subTxt">Test01 Examples</p>
          </div>
          <div className="iqExample-attention">本画面の例題説明をよく読んで設問へ進んでください。</div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題1を見てください。<br />左側に4つの枠が並んでいますが、4つ目の枠の中には何もかいてなくて空白です。この空白の枠の中に最もよく当てはまる図を右側の6つの図の中から一つ選ぶ問題です。3つの図は、小さな木がだんだんと傾いてきていると想像してください。<br />次にくる図はどれですか。</p>
              <div className="iqExample-sample-test iqTest partA">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題1</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_01} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">B</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_02} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">C</p>
                      <p className="iqTest-question-border"><img src={testIcon_01_q_03} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">D</p>
                      <p className="iqTest-question-border is-blank"><img src={testIcon_blank} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-1" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-1"><img src={testIcon_01_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-2" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-2"><img src={testIcon_01_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-3" type="radio" name="sample01" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-3"><img src={testIcon_01_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-4" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-4"><img src={testIcon_01_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-5" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-5"><img src={testIcon_01_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample1-6" type="radio" name="sample01" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample1-6"><img src={testIcon_01_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">この正解はすでに解答欄で3が選択されています。<br />理由は、左側の3番目の木よりも、もっと傾いていて、空白の枠によく当てはまるからです。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題2を見てください。<br />これは上下に動いて開く窓の図を想像してください。枠の中の黒い部分が徐々に低くなっていますが、4番目にくる図はどれでしょうか？</p>
              <div className="iqExample-sample-test iqTest partA">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題2</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_01} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">B</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_02} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">C</p>
                      <p className="iqTest-question-border"><img src={testIcon_02_q_03} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">D</p>
                      <p className="iqTest-question-border is-blank"><img src={testIcon_blank} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-1" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-1"><img src={testIcon_02_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-2" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-2"><img src={testIcon_02_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-3" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-3"><img src={testIcon_02_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-4" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-4"><img src={testIcon_02_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-5" type="radio" name="sample02" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-5"><img src={testIcon_02_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample2-6" type="radio" name="sample02" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample2-6"><img src={testIcon_02_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、5です。<br />3番目は半分くらいおりたところだとすれば、次は半分よりもっと下になります。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題3を見てください。<br />この図は順番に何かがだんだんと増えているようです。<br />3番目の図では一番上の方から始まって右回りに3つになっています。<br />それでは4番目の空白にくる図はどれでしょうか？</p>
              <div className="iqExample-sample-test iqTest partA">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題3</div>
                  <ul className="iqTest-question-list">
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">A</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_01} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">B</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_02} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">C</p>
                      <p className="iqTest-question-border"><img src={testIcon_03_q_03} alt="" /></p>
                    </li>
                    <li className="iqTest-question-item">
                      <p className="iqTest-question-index">D</p>
                      <p className="iqTest-question-border is-blank"><img src={testIcon_blank} alt="" /></p>
                    </li>
                  </ul>
                </div>
                <div className="iqTest-Answer">
                  <div className="iqTest-Answer-number">回答選択</div>
                  <ul className="iqTest-Answer-list">
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">1</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-1" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-1"><img src={testIcon_03_a_01} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">2</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-2" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-2"><img src={testIcon_03_a_02} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">3</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-3" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-3"><img src={testIcon_03_a_03} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">4</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-4" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-4"><img src={testIcon_03_a_04} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">5</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-5" type="radio" name="sample03" defaultChecked />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-5"><img src={testIcon_03_a_05} alt="" /></label>
                    </li>
                    <li className="iqTest-Answer-item">
                      <p className="iqTest-Answer-index">6</p>
                      <input className="iqTest-Answer-checkbox" id="sample3-6" type="radio" name="sample03" />
                      <label className="iqTest-Answer-checkItem" htmlFor="sample3-6"><img src={testIcon_03_a_06} alt="" /></label>
                    </li>
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、5です。<br />右側の列にある図の中には4つあるものがたくさんありますが、その中で一番上から、右回りに4つになって正しい位置にあるものは5であることがわかります。</p>
            </div>
          </div>
          <div className="iqExample-text is-center">それでは「検査を開始する」のボタンを押して、1問目の問題から始めてください。</div>
          <DescriptionAgreeBtn to="/potential/test01">検査を開始する</ DescriptionAgreeBtn>
        </div>
      </section>
    </LayoutBasic>
  );
}

// export default IqTestDescription01;
export default withRouter(IqTestDescription01);
