import React from 'react';
import { withRouter } from "react-router-dom";

// import Component
import LayoutBasic from './../LayoutBasic';
import { AnswerInputCheckItem } from './elements/Items';
import DescriptionAgreeBtn from './elements/DescriptionAgreeBtn';

// import function
import { iqTestFinishedCheck } from './../../functions/TestStatusCheck';
import { setZeroSum2 } from './../common/functions/setZeroSum';

// import image
import testIcon_01_a_01 from './../../assets/images/iqtest/case06/ex/01_a_01.png';
import testIcon_01_a_02 from './../../assets/images/iqtest/case06/ex/01_a_02.png';
import testIcon_01_a_03 from './../../assets/images/iqtest/case06/ex/01_a_03.png';
import testIcon_01_a_04 from './../../assets/images/iqtest/case06/ex/01_a_04.png';
import testIcon_01_a_05 from './../../assets/images/iqtest/case06/ex/01_a_05.png';
import testIcon_02_a_01 from './../../assets/images/iqtest/case06/ex/02_a_01.png';
import testIcon_02_a_02 from './../../assets/images/iqtest/case06/ex/02_a_02.png';
import testIcon_02_a_03 from './../../assets/images/iqtest/case06/ex/02_a_03.png';
import testIcon_02_a_04 from './../../assets/images/iqtest/case06/ex/02_a_04.png';
import testIcon_02_a_05 from './../../assets/images/iqtest/case06/ex/02_a_05.png';

const testIconPaths = {
  "01_a_01" : testIcon_01_a_01,
  "01_a_02" : testIcon_01_a_02,
  "01_a_03" : testIcon_01_a_03,
  "01_a_04" : testIcon_01_a_04,
  "01_a_05" : testIcon_01_a_05,
  "02_a_01" : testIcon_02_a_01,
  "02_a_02" : testIcon_02_a_02,
  "02_a_03" : testIcon_02_a_03,
  "02_a_04" : testIcon_02_a_04,
  "02_a_05" : testIcon_02_a_05,
}

const sampleAnswers = {
  'answer01' : ["2","4"],
  'answer02' : ["3","5"],
}

const IqTestDescription06 = (props) => {
  React.useEffect(() => {
    iqTestFinishedCheck(props);
  },[props]);

  // 回答データ格納用配列を設定
  const [answerDatas, setAnswerDatas] = React.useState(sampleAnswers);

  // 回答ボタン選択時の処理
  const handleChange = (e) => {
    let changeArray = answerDatas[e.target.name];
    let setValue = e.target.value;

    if(changeArray.some(target => target === setValue)){
      if(changeArray[0] === setValue){
        changeArray[0] = null;
      }
      if(changeArray[1] === setValue){
        changeArray[1] = null;
      }
    }else{
      if(changeArray[0] === null){
        changeArray[0] = setValue;
      }else if(changeArray[1] === null){
        changeArray[1] = setValue;
      }  
    }

    // console.log(changeArray);
    setAnswerDatas({ ...answerDatas, [e.target.name]: changeArray });
  }

  // 回答選択肢の数だけ配列を作成
  const sampleAnswerVlues = ["1","2","3","4","5"];

  console.log(answerDatas);

  return (
    <LayoutBasic>
      <section className="iqExample">
        <div className="iqExample-container">
          <div className="iqExample-title pageTitle">
            <p className="pageTitle-mainTxt">検査06 -例題-</p>
            <p className="pageTitle-subTxt">Test06 Examples</p>
          </div>
          <div className="iqExample-attention">本画面の例題説明をよく読んで設問へ進んでください。</div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題1は、横に5つの図が並んでいますが、そのうち2つは他の3つとは違っています。この問題は、5つの中から他の3つとは違う2つを、選ぶ問題です。</p>
              <p className="iqExample-sample-detail">例題1の正解は、番号の若い順から2と4で、すでに解答欄に選択してあります。</p>
              <div className="iqExample-sample-test iqTest partB">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題1</div>
                </div>
                <div className="iqTest-Answer">
                  <ul className="iqTest-Answer-list">
                    {sampleAnswerVlues.map((data) =>
                      <AnswerInputCheckItem
                        key={"SampleAnswer01-" + setZeroSum2(data)}
                        anwserIndex="1"
                        selectIndex={ data }
                        handleChange={ handleChange }
                        answers={ answerDatas }
                        answerValue={answerDatas["answer01"]}
                        testIconPath={ testIconPaths["01_a_" + setZeroSum2(data)] }
                      />
                    )}
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">2と4は、ただの円であり、他の3つの図と違うので、これらの2つを選びます。</p>
            </div>
          </div>
          <div className="iqExample-sample">
            <div className="iqExample-sample-inner">
              <p className="iqExample-sample-detail">例題2を見てください。<br />2つだけ他と違うものを見つけてください。</p>
              <div className="iqExample-sample-test iqTest partB">
                <div className="iqTest-question">
                  <div className="iqTest-question-number">例題2</div>
                </div>
                <div className="iqTest-Answer">
                  <ul className="iqTest-Answer-list">
                    {sampleAnswerVlues.map((data) =>
                      <AnswerInputCheckItem
                        key={"SampleAnswer02-" + setZeroSum2(data)}
                        anwserIndex="2"
                        selectIndex={ data }
                        handleChange={ handleChange }
                        answers={ answerDatas }
                        answerValue={answerDatas["answer02"]}
                        testIconPath={ testIconPaths["02_a_" + setZeroSum2(data)] }
                      />
                    )}
                  </ul>
                </div>
              </div>
              <p className="iqExample-sample-detail">正解は、3と5です。<br />点々のある四角は他の3つと違います。</p>
            </div>
          </div>
          <div className="iqExample-text is-center">それでは「検査を開始する」のボタンを押して、1問目の問題から始めてください。</div>
          <DescriptionAgreeBtn to="/potential/test06">検査を開始する</ DescriptionAgreeBtn>
        </div>
      </section>
    </LayoutBasic>
  );
}

// export default IqTestDescription06;
export default withRouter(IqTestDescription06);
