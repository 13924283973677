import axios from "axios";

// import function
import { encrypte, decrypte } from './../components/common/functions/encryption.js';
import { apiUrl_userLogin } from './../utility/urls';

class User {
  isLoggedIn = () => this.get('isLoggedIn') === 'true';

  set = (key, value) => localStorage.setItem(key, value);

  get = key => this.getLocalStorage(key);

  remove = (key) => {
    if(this.get(key)){
      localStorage.removeItem(key);
    }
    return true;
  }

  getLocalStorage = key => {
    const ret = localStorage.getItem(key);
    if (ret) {
      return ret;
    }
    return null;
  };

  loginCheck(){
    console.log("---- LoginCheck START");

    if(this.get('isLoggedInEffectiveDate') === false){
      console.log("LocalStorageItem 'isLoggedInEffectiveDate' is NONE");
      return false;
    }

    const nowDate = new Date(Date.now()).getTime();
    const effectiveDate = this.get('isLoggedInEffectiveDate');

    console.log("nowDate : " + nowDate);
    console.log("effectiveDate : " + effectiveDate);

    if(nowDate > effectiveDate){
      console.log("Login Expiration After");
      this.logout();
      return false;
    }

    console.log("Login Expiration before");
    this.updateLoginEffectiveDate();

    return this.get('isLoggedIn') === 'true';
  }

  login(passcode){
    console.log("---- Login START");

    let result = "";
    const POST_DATA = { passcode : passcode };
    // const API_URL = '/api/user_login/';
    const API_URL = apiUrl_userLogin;

    // ログイン通信処理
    return new Promise((resolve,reject) => {
      setTimeout(() => {
        axios.post(API_URL,POST_DATA)
        .then((res) => {
          console.log(res.data);
          if(res.data['result']){
            console.log("---- Login COMPLETE");
            this.set('isLoggedIn', true);
            this.set('isLoggedInUserID', encrypte(res.data['userid']));
            this.updateLoginEffectiveDate();
            resolve(true);
          }else{
            console.log("---- Login FAILED");
            this.removeLocalDatas();
            result = res.data['message'] ? res.data['message'] : "処理時の予期せぬエラー";
            reject(result);
          }

        }).catch((res) => {
          console.log("---- Login FAILED");
          this.removeLocalDatas();
          console.log(res);
          result['message'] = "サーバーとの通信に失敗しました。";
          reject(result['message']);
        });
      }, 1000);
    })
  };

  async logout(){
    this.removeLocalDatas();
  };

  updateLoginEffectiveDate(){
    const date = new Date();
    // const effectiveDate = date.setMinutes(date.getMinutes() + 1);
    const effectiveDate = date.setDate(date.getDate() + 1);
    this.set('isLoggedInEffectiveDate', effectiveDate);
  }

  getUserId(){
    let userid;
    if(this.get('isLoggedInUserID')){
      userid = decrypte(this.get('isLoggedInUserID'));
    }

    return userid;
  }

  removeLocalDatas(){
    this.remove('isLoggedIn');
    this.remove('isLoggedInUserID');
    this.remove('isLoggedInEffectiveDate');
  }

}

export default new User();