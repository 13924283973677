import React from 'react';
import { withRouter } from 'react-router-dom'

// import Components
import LayoutFullscreen from './../components/LayoutFullscreen';
import ProccessMask from './ProccessMask';

// import Functions
import { passCreateSend } from './../functions/passCreateSend';
import { redirectAction } from './common/functions/redirectAction';

class passCreate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      telValue: '',
      proccessType: 'createPass',
      proccessState: 'none',
      errorMassage: ''
    };

    this.inputChange = this.inputChange.bind(this);
    this.inputSubmit = this.inputSubmit.bind(this);
  }

  // DOMにアクセス可能後に実行する初期処理
  async componentDidMount() {
    await setTimeout( () => {
      console.log("componentDidMount");
      return true;
    }, 2000);
  }

  inputChange(event) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  inputSubmit(event) {
    event.preventDefault();
    this.passSend(this.state.telValue).catch(() => { return false; });
  }

  inputValidation(value){
    return new Promise((resolve,reject) => {
      if(value === '' || value === null){
        reject("メールアドレスが入力されていません");
      }

      if(!value.match(/.+@.+\..+/)){
        reject("メールアドレスが正しくありません");
      }

      resolve();
    })
  }

  async proccessChange(status,inTime,outTime){
    const delayTime = inTime === "" || inTime === null ? 0 : inTime;
    const timeOut = outTime === "" || outTime === null ? 0 : outTime;
    console.log("delayTime: " + delayTime)
    console.log("timeOut: " + timeOut)
    await setTimeout(() => {
      this.setState({
        proccessState: status
      });
      console.log("proccessChange: " + this.state.proccessState)
    },delayTime)
    await new Promise((resolve) => setTimeout(resolve, timeOut));
    return true;
  }

  async passSend(mail){
    console.log("---- passSend");
    this.setState({ errorMassage: '' });
    await this.inputValidation(mail).catch((error) => {
      throw this.passSendError(error);
    });
    await this.proccessChange("active",0,0);
    await passCreateSend(mail).catch((error) => {
      throw this.passSendError(error);
    });
    await this.proccessChange("finish",0,600);
    await this.proccessChange("complete",0,1000);
    return redirectAction(this.props, "/login");
  }

  passSendError(error){
    console.log("---- passSendError");
    this.proccessChange("none",0,0);
    this.setState({
      errorMassage: error
    });
    console.log("passCreate.js : errorMassage is " + this.state.errorMassage);
  }

  render() {
    return (
      <LayoutFullscreen>
        <section className="login">
          <ProccessMask
            proccessType={this.state.proccessType}
            proccessFlag={this.state.proccessState}
          />
          <div className="login-container">
            <div className="login-title pageTitle is-left">
              <p className="pageTitle-mainTxt">ワンタイムパスコード生成</p>
              <p className="pageTitle-subTxt">Passcode Request</p>
            </div>
            <div className="login-detail">
              <p className="login-detail-text">この度は、株式会社シェルトの採用にご応募、ありがとうございます。<br />採用選考に伴い、適性検査を行わせて頂きます。<br />適性検査を実施するにあたり、本人確認の為、ワンタイムパスコードが必要になります。<br />ワンタイムパスコードは、応募の際にご連絡頂いている、メールアドレスに送信いたします。<br />下記より、お使いのメールアドレスをご入力ください。</p>
            </div>
            <form className="login-form loginForm" action="login" onSubmit={this.inputSubmit}>
              <p className="loginForm-title">パスコード送付先メールアドレス</p>
              <input
                className="loginForm-input"
                type="tel"
                name="telValue"
                onChange={this.inputChange}
                autoComplete="off"
              />
              <p className="loginForm-error">{this.state.errorMassage}</p>
              <div className="loginForm-submit">
                <input className="btn" type="submit" value="送信" />
              </div>
            </form>
            <div className="login-tips">
              <div className="login-tips-inner">
                <p className="login-tips-text">【諸注意】<br />・「入力されたメールアドレスの登録がありません」と表示される<br />・ワンタイムパスワードが届かない<br />上記のような場合は、弊社採用担当者までご連絡ください。</p>
                <p className="login-tips-text">システム担当 ： system@cierto.co.jp</p>
              </div>
            </div>
          </div>
        </section>
      </LayoutFullscreen>
    );
  }
}

// export default passCreate;
export default withRouter(passCreate);
