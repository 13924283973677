import React from 'react';

function TestFinishMask(props) {
  let addClass;
  let proccessText;
  let proccessSubText;

  if(props.finishFlag){
    addClass = "is-active";
  }else{
    addClass = "";
  }

  switch (props.next) {
    case "breakTime":
      proccessText = "TIME UP";
      proccessSubText = "次の画面に移動します";
      break;
    case "finish":
      proccessText = "TIME UP";
      proccessSubText = "次の画面に移動します";
      break;
    default:
      proccessText = "TIME UP";
      proccessSubText = "次のテストに移動します";
  }

  return (
    <div className={'l-basic-testFinishMask testFinishMask ' + addClass}>
      <div className="testFinishMask-inner">
        <p className="testFinishMask-text">{proccessText}</p>
        <p className="testFinishMask-subText">{proccessSubText}</p>
      </div>
    </div>
  );
}

export default TestFinishMask;
