const TestSetDatasProd = {
    "index": {
        "nextStep" : "case01",
        "nextPath" : "/potential/description01",
    },
    "test01": {
        "caseNum"  : "1",
        "caseName" : "検査01",
        "caseCategory" : "partA",
        "qCount"   : 13,
        "rCount"   : 4,
        "aCount"   : 6,
        "nextStep" : "case02",
        "nextPath" : "/potential/description02",
        "limitTime" : 180000,
        "limitTimeStr" : "3分",
    },
    "test02": {
        "caseNum"  : "2",
        "caseName" : "検査02",
        "caseCategory" : "partB",
        "qCount"   : 13,
        "rCount"   : 0,
        "aCount"   : 5,
        "nextStep" : "case03",
        "nextPath" : "/potential/description03",
        "limitTime" : 240000,
        "limitTimeStr" : "4分",
    },
    "test03": {
        "caseNum"  : "3",
        "caseName" : "検査03",
        "caseCategory" : "partC",
        "qCount"   : 13,
        "rCount"   : 1,
        "aCount"   : 6,
        "nextStep" : "case04",
        "nextPath" : "/potential/description04",
        "limitTime" : 180000,
        "limitTimeStr" : "3分",
    },
    "test04": {
        "caseNum"  : "4",
        "caseName" : "検査04",
        "caseCategory" : "partD",
        "qCount"   : 10,
        "rCount"   : 1,
        "aCount"   : 5,
        "nextStep" : "breakTime",
        "nextPath" : "/potential/breakTime",
        "limitTime" : 150000,
        "limitTimeStr" : "2分30秒",
    },
    "breakTime": {
        "nextStep" : "case05",
        "nextPath" : "/potential/description05",
        "limitTime" : 180000,
        "limitTimeStr" : "3分",
    },
    "test05": {
        "caseNum"  : "5",
        "caseName" : "検査05",
        "caseCategory" : "partA",
        "qCount"   : 13,
        "rCount"   : 4,
        "aCount"   : 6,
        "nextStep" : "case06",
        "nextPath" : "/potential/description06",
        "limitTime" : 180000,
        "limitTimeStr" : "3分",
    },
    "test06": {
        "caseNum"  : "6",
        "caseName" : "検査06",
        "caseCategory" : "partB",
        "qCount"   : 13,
        "rCount"   : 0,
        "aCount"   : 5,
        "nextStep" : "case07",
        "nextPath" : "/potential/description07",
        "limitTime" : 240000,
        "limitTimeStr" : "4分",
    },
    "test07": {
        "caseNum"  : "7",
        "caseName" : "検査07",
        "caseCategory" : "partC",
        "qCount"   : 13,
        "rCount"   : 1,
        "aCount"   : 6,
        "nextStep" : "case08",
        "nextPath" : "/potential/description08",
        "limitTime" : 180000,
        "limitTimeStr" : "3分",
    },
    "test08": {
        "caseNum"  : "8",
        "caseName" : "検査08",
        "caseCategory" : "partD",
        "qCount"   : 10,
        "rCount"   : 1,
        "aCount"   : 5,
        "nextStep" : "finish",
        "nextPath" : "/allComplete",
        "limitTime" : 150000,
        "limitTimeStr" : "2分30秒",
    },
}

export default TestSetDatasProd;
