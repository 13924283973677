const domain = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "";

export const apiUrl_userLogin = domain + "/api/user_login/";
export const apiUrl_issuePassword = domain + '/api/issue_password/';
export const apiUrl_checkImplemented = domain + "/api/check_implemented/";

export const apiUrl_submitTestresult = domain + '/api/submit_testresult/';

export const apiUrl_submitCattellTestresult = domain + '/cattell/api/submit_cattell_testresult/';

export const url_errorPage = "/error";
