import React from 'react';
// import { Link } from "react-router-dom";

// import Component
import LayoutFullscreen from './../LayoutFullscreen';
import DescriptionAgreeBtn from './elements/DescriptionAgreeBtn';

// import function
import { iqTestFinishedCheck } from './../../functions/TestStatusCheck';

const IqTestStart = (props) => {
  React.useEffect(() => {
    iqTestFinishedCheck(props);
  },[props]);

  return (
    <LayoutFullscreen>
      <section className="description">
        <div className="description-container">
          <div className="description-title pageTitle">
            <p className="pageTitle-mainTxt">潜在力診断</p>
            <p className="pageTitle-subTxt">Potential Diagnosis</p>
          </div>
          <div className="description-detail">
            <p className="description-detail-text">●本検査は4種類、合計8つの検査があり、検査毎に説明と時間を計りながら進めていきます。</p>
            <p className="description-detail-text">●各検査には、解き方がわかるように例題があります。最初に例題を確認してから設問回答に進みます。</p>
            <p className="description-detail-text">●検査時間が終了すると自動で次の検査画面に進みます。早く終了した場合はそのままお待ちください。</p>
            <p className="description-detail-text">●それぞれの検査で出来るだけたくさん回答をしてください。</p>
          </div>
          <DescriptionAgreeBtn to="/potential/description01">検査を開始する</ DescriptionAgreeBtn>
        </div>
      </section>
    </LayoutFullscreen>
  );
}

// export default IqTestStart;
export default IqTestStart;
