const InitialAnswers = {
  "test01": {
    'answer01' : "0",
    'answer02' : "0",
    'answer03' : "0",
    'answer04' : "0",
    'answer05' : "0",
    'answer06' : "0",
    'answer07' : "0",
    'answer08' : "0",
    'answer09' : "0",
    'answer10' : "0",
    'answer11' : "0",
    'answer12' : "0",
    'answer13' : "0",  
  },
  "test02": {
    'answer01' : ["0","0"],
    'answer02' : ["0","0"],
    'answer03' : ["0","0"],
    'answer04' : ["0","0"],
    'answer05' : ["0","0"],
    'answer06' : ["0","0"],
    'answer07' : ["0","0"],
    'answer08' : ["0","0"],
    'answer09' : ["0","0"],
    'answer10' : ["0","0"],
    'answer11' : ["0","0"],
    'answer12' : ["0","0"],
    'answer13' : ["0","0"],
    'answer14' : ["0","0"],
  },
  "test03": {
    'answer01' : "0",
    'answer02' : "0",
    'answer03' : "0",
    'answer04' : "0",
    'answer05' : "0",
    'answer06' : "0",
    'answer07' : "0",
    'answer08' : "0",
    'answer09' : "0",
    'answer10' : "0",
    'answer11' : "0",
    'answer12' : "0",
    'answer13' : "0",  
  },
  "test04": {
    'answer01' : "0",
    'answer02' : "0",
    'answer03' : "0",
    'answer04' : "0",
    'answer05' : "0",
    'answer06' : "0",
    'answer07' : "0",
    'answer08' : "0",
    'answer09' : "0",
    'answer10' : "0",
  },
  "test05": {
    'answer01' : "0",
    'answer02' : "0",
    'answer03' : "0",
    'answer04' : "0",
    'answer05' : "0",
    'answer06' : "0",
    'answer07' : "0",
    'answer08' : "0",
    'answer09' : "0",
    'answer10' : "0",
    'answer11' : "0",
    'answer12' : "0",
    'answer13' : "0",  
  },
  "test06": {
    'answer01' : ["0","0"],
    'answer02' : ["0","0"],
    'answer03' : ["0","0"],
    'answer04' : ["0","0"],
    'answer05' : ["0","0"],
    'answer06' : ["0","0"],
    'answer07' : ["0","0"],
    'answer08' : ["0","0"],
    'answer09' : ["0","0"],
    'answer10' : ["0","0"],
    'answer11' : ["0","0"],
    'answer12' : ["0","0"],
    'answer13' : ["0","0"],
    'answer14' : ["0","0"],
  },
  "test07": {
    'answer01' : "0",
    'answer02' : "0",
    'answer03' : "0",
    'answer04' : "0",
    'answer05' : "0",
    'answer06' : "0",
    'answer07' : "0",
    'answer08' : "0",
    'answer09' : "0",
    'answer10' : "0",
    'answer11' : "0",
    'answer12' : "0",
    'answer13' : "0",  
  },
  "test08": {
    'answer01' : "0",
    'answer02' : "0",
    'answer03' : "0",
    'answer04' : "0",
    'answer05' : "0",
    'answer06' : "0",
    'answer07' : "0",
    'answer08' : "0",
    'answer09' : "0",
    'answer10' : "0",
  },
}

export default InitialAnswers;